// When adding new items to this file, please also add the rgb equivalent
// to the global-rgb file as well.

// Root tokens
:root {
  --mc-color-red-100-rgb: 255, 214, 21;
  --mc-color-red-200-rgb: 255, 173, 17;
  --mc-color-red-300-rgb: 255, 133, 13;
  --mc-color-red-400-rgb: 255, 92, 92;
  --mc-color-red-500-rgb: 255, 51, 51;
  --mc-color-red-600-rgb: 255, 0, 0;
  --mc-color-red-700-rgb: 224, 0, 0;
  --mc-color-red-800-rgb: 184, 0, 0;
  --mc-color-red-900-rgb: 143, 0, 0;

  --mc-color-pink-100-rgb: 254, 176, 194;
  --mc-color-pink-200-rgb: 234, 93, 126;
  --mc-color-pink-300-rgb: 232, 74, 111;
  --mc-color-pink-400-rgb: 232, 74, 111;
  --mc-color-pink-500-rgb: 227, 38, 82;
  --mc-color-pink-600-rgb: 214, 26, 70;
  --mc-color-pink-700-rgb: 199, 26, 66;
  --mc-color-pink-800-rgb: 181, 23, 60;
  --mc-color-pink-900-rgb: 162, 21, 54;

  --mc-color-yellow-100-rgb: 255, 220, 173;
  --mc-color-yellow-200-rgb: 255, 202, 133;
  --mc-color-yellow-300-rgb: 255, 184, 86;
  --mc-color-yellow-400-rgb: 255, 167, 51;
  --mc-color-yellow-500-rgb: 255, 144, 0;
  --mc-color-yellow-600-rgb: 232, 133, 3;
  --mc-color-yellow-700-rgb: 209, 122, 8;
  --mc-color-yellow-800-rgb: 185, 92, 5;
  --mc-color-yellow-900-rgb: 102, 58, 0;

  --mc-color-green-100-rgb: 194, 235, 210;
  --mc-color-green-200-rgb: 163, 224, 188;
  --mc-color-green-300-rgb: 133, 214, 165;
  --mc-color-green-400-rgb: 102, 204, 143;
  --mc-color-green-500-rgb: 62, 187, 112;
  --mc-color-green-600-rgb: 56, 168, 101;
  --mc-color-green-700-rgb: 46, 138, 83;
  --mc-color-green-800-rgb: 36, 107, 64;
  --mc-color-green-900-rgb: 25, 77, 46;

  --mc-color-purple-100-rgb: 212, 202, 247;
  --mc-color-purple-200-rgb: 183, 167, 241;
  --mc-color-purple-300-rgb: 154, 131, 236;
  --mc-color-purple-400-rgb: 125, 96, 230;
  --mc-color-purple-500-rgb: 71, 33, 208;
  --mc-color-purple-600-rgb: 60, 28, 176;
  --mc-color-purple-700-rgb: 48, 22, 128;
  --mc-color-purple-800-rgb: 36, 17, 106;
  --mc-color-purple-900-rgb: 25, 11, 80;

  --mc-color-neutral-90-rgb:   255, 255, 255;
  --mc-color-neutral-100-rgb:  244, 244, 245;
  --mc-color-neutral-200-rgb:  233, 234, 236;
  --mc-color-neutral-300-rgb:  212, 213, 217;
  --mc-color-neutral-400-rgb:  179, 181, 188;
  --mc-color-neutral-500-rgb:  158, 160, 169;
  --mc-color-neutral-600-rgb:  86, 89, 97;
  --mc-color-neutral-700-rgb:  67, 69, 76;
  --mc-color-neutral-800-rgb:  48, 49, 54;
  --mc-color-neutral-900-rgb:  34, 35, 38;
  --mc-color-neutral-1000-rgb: 13, 13, 14;
  --mc-color-neutral-1010-rgb: 0, 0, 0;
}
