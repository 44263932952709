$whitespace: 5% !default;

.mc-nameplate {
  vertical-align: middle;

  &__inner {
    margin: 0 auto;
    max-width: 368px;

    .mc-nameplate--small & {
      max-width: 220px;
    }

    .mc-nameplate--large & {
      max-width: 500px;
    }

    .mc-nameplate--fill & {
      width: 100%;
      max-width: none;
    }
  }

  &__logo {
    display: block;
    width: 50%;
    margin: 0 auto;
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__separator {
    display: block;
    margin: var(--mc-scale-3) auto var(--mc-scale-6);
    width: var(--mc-scale-8);
    height: var(--mc-scale-1);
    background: var(--mc-theme-text);

    .mc-nameplate--small & {
      margin: var(--mc-scale-3) auto var(--mc-scale-3);
      width: var(--mc-scale-4);
    }

    .mc-nameplate--large & {
      margin: var(--mc-scale-6) auto var(--mc-scale-6);
    }
  }
}
