.mc-text-hr {
  position: relative;
  overflow: hidden;
  text-align: center;

  span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.75em;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: var(--mc-theme-text-hr-span-after);
    }

    &:before {
      left: 100%;
    }

    &:after {
      right: 100%;
    }
  }
}
