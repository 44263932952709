// Allow users to begin just setting a background
// color intsead of using the component
.mc-bg-gray-100 {
  background: $mc-color-gray-100;
}
.mc-bg-gray-200 {
  background: $mc-color-gray-200;
}
.mc-bg-gray-300 {
  background: $mc-color-gray-300;
}
.mc-bg-gray-400 {
  background: $mc-color-gray-400;
}
.mc-bg-gray-500 {
  background: $mc-color-gray-500;
}
.mc-bg-gray-600 {
  background: $mc-color-gray-600;
}
.mc-bg-gray-700 {
  background: $mc-color-gray-700;
}
.mc-bg-gray-800 {
  background: $mc-color-gray-800;
}

.mc-bg-container-tint-dark {
  background: var(--mc-color-bg-container-tint-dark);
}

.mc-bg-container-tint-light {
  background: var(--mc-color-bg-container-tint-light);
}

.mc-bg-container-tint-medium {
  background: var(--mc-color-bg-container-tint-medium);
}

.mc-bg-container-dark {
  background: var(--mc-color-bg-container-dark);
}

.mc-bg-container-medium {
  background: var(--mc-color-bg-container-medium);
}

.mc-bg-light {
  background: $mc-color-light;
}
.mc-bg-dim {
  background: rgba($mc-color-dark, 0.7);
}
.mc-bg-dark {
  background: $mc-color-dark;
}
.mc-bg-primary {
  background: $mc-color-primary;
}
.mc-bg-secondary {
  background: $mc-color-secondary;
}
.mc-bg-tertiary {
  background: $mc-color-gray-400;
}
.mc-bg-error {
  background: $mc-color-error;
}
.mc-bg-success {
  background: $mc-color-success;
}
.mc-bg-reward {
  background: $mc-color-reward;
}
.mc-bg-highlight {
  background: $mc-color-highlight;
}
.mc-bg-warning {
  background: var(--mc-color-text-warning);
}
.mc-bg-neutral-100 {
  background: var(--mc-color-neutral-100);
}
.mc-bg-neutral-800 {
  background: var(--mc-color-neutral-800);
}
.mc-bg-neutral-900 {
  background: var(--mc-color-neutral-900);
}
.mc-bg-neutral-1000 {
  background: var(--mc-color-neutral-1000);
}

.mc-bg-neutral-1010 {
  background: var(--mc-color-neutral-1010);
}

.mc-bg-theme {
  background: var(--mc-theme-background);
}

.mc-bg-transparent {
  background: transparent;
}

.mc-bg-hover-transparent {
  &:hover {
    background: transparent;
  }
}

.mc-bg-unset {
  background: unset;
}

.mc-bg-yellow-100 {
  background: var(--mc-color-yellow-100);
}
