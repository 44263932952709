.mc-carousel {
  &--highlight-active {
    .slick-list {
      .slick-active {
        opacity: 0.5;
        transition: opacity 250ms ease;

        &.slick-current {
          opacity: 1;
        }
      }
    }
  }

  &--highlight-hover {
    .slick-list:hover {
      .slick-active {
        opacity: 0.5;
        transition: opacity 250ms ease;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  // These shouldn't be conditional on breakpoint, they should be
  // conditional on reaching beginning / end of carousel
  // but carousel needs additional js for that functionality, so I just
  // tie into class names for arrows :/
    &__peek {
      &--before,
      &--after {
        content: "";
        display: block;
        position: absolute;
        left: auto;
        right: -16px;
        top: 0;
        width: 120px;
        height: 100%;
        z-index: 1;
        pointer-events: none;
        opacity: 1;
        transition: opacity 550ms ease;
      }

      &--before {
        left: -16px;
        right: auto;
      }
    }

    /* Conditional show/hide peek */
    &--start {
      .mc-carousel__peek--before { opacity: 0; }
    }

    &--end {
      .mc-carousel__peek--after { opacity: 0; }
    }
  }
