.mc-overflow {
  &--hidden {
    overflow: hidden;
  }
  &--auto {
    overflow: auto;
  }

  &--scroll {
    overflow: scroll;
  }

  &--visible {
    overflow: visible;
  }
}
