// If you add any new alias tokens to this file, be sure to update the
// documentation at web/ui/design-system/src/foundation/tokens/index.stories.js

// Please also add the hex equivalent to the global-hex file as well.

// Alias Tokens
:root,
.mc-theme-dark {
  // Button Actions
  --mc-color-primary-default-rgb: var(--mc-color-primary-500-rgb);
  --mc-color-primary-hover-rgb: var(--mc-color-primary-600-rgb);
  --mc-color-primary-pressed-rgb: var(--mc-color-primary-700-rgb);
  --mc-color-primary-disabled-rgb: var(--mc-color-primary-100-rgb);

  --mc-color-utility-default-rgb: var(--mc-color-utility-500-rgb);
  --mc-color-utility-hover-rgb: var(--mc-color-utility-600-rgb);
  --mc-color-utility-pressed-rgb: var(--mc-color-utility-700-rgb);
  --mc-color-utility-disabled-rgb: var(--mc-color-utility-100-rgb);

  --mc-color-play-default-rgb: var(--mc-color-neutral-90-rgb);
  --mc-color-play-hover-rgb: var(--mc-color-neutral-200-rgb);
  --mc-color-play-pressed-rgb: var(--mc-color-neutral-300-rgb);
  --mc-color-play-disabled-rgb: var(--mc-color-neutral-100-rgb);
  --mc-color-play-text-default-rgb: var(--mc-color-neutral-1010-rgb);
  --mc-color-play-text-disabled-rgb: var(--mc-color-neutral-500-rgb);

  --mc-color-secondary-default-rgb: var(--mc-color-neutral-700-rgb);
  --mc-color-secondary-hover-rgb: var(--mc-color-neutral-800-rgb);
  --mc-color-secondary-pressed-rgb: var(--mc-color-neutral-900-rgb);
  --mc-color-secondary-disabled-rgb: var(--mc-color-neutral-300-rgb);

  --mc-color-tertiary-default-rgb: transparent;
  --mc-color-tertiary-hover-rgb: var(--mc-color-neutral-800-rgb);
  --mc-color-tertiary-pressed-rgb: transparent;
  --mc-color-tertiary-disabled-rgb: var(--mc-color-neutral-600-rgb);
  --mc-color-tertiary-border-default-rgb: var(--mc-color-neutral-90-rgb);

  --mc-color-control-default-rgb: var(--mc-color-neutral-700-rgb);
  --mc-color-control-hover-rgb: var(--mc-color-neutral-800-rgb);
  --mc-color-control-pressed-rgb: var(--mc-color-neutral-900-rgb);
  --mc-color-control-disabled-rgb: var(--mc-color-neutral-300-rgb);

  --mc-color-third-party-default-rgb: var(--mc-color-neutral-1000-rgb);
  --mc-color-third-party-hover-rgb: var(--mc-color-neutral-800-rgb);
  --mc-color-third-party-pressed-rgb: var(--mc-color-neutral-1000-rgb);

  // Element focus
  --mc-color-element-focus-rgb: var(--mc-color-focus-rgb);
  --mc-color-element-focus-outline-rgb: var(--mc-color-neutral-90-rgb);
  --mc-color-element-focus-border-rgb: var(--mc-color-neutral-1010-rgb);

  // Container backgrounds
  --mc-color-bg-container-default-rgb: var(--mc-color-bg-container-dark-rgb);
  --mc-color-bg-container-dark-rgb: var(--mc-color-neutral-1000-rgb);
  --mc-color-bg-container-medium-rgb: var(--mc-color-neutral-100-rgb);
  --mc-color-bg-container-light-rgb: var(--mc-color-neutral-90-rgb);
  --mc-color-bg-container-tint-light-rgb: var(--mc-color-neutral-700-rgb);
  --mc-color-bg-container-tint-medium-rgb: var(--mc-color-neutral-800-rgb);
  --mc-color-bg-container-tint-dark-rgb: var(--mc-color-neutral-900-rgb);
  --mc-color-bg-container-disabled-rgb: var(--mc-color-neutral-100-rgb);
  --mc-color-bg-container-success-rgb: var(--mc-color-success-100-rgb);
  --mc-color-bg-container-warning-rgb: var(--mc-color-warning-100-rgb);
  --mc-color-bg-container-error-rgb: var(--mc-color-error-100-rgb);
  --mc-color-bg-page-default-rgb: var(--mc-color-neutral-1000-rgb);
  --mc-color-bg-tag-rgb: var(--mc-color-neutral-700-rgb);
  --mc-color-bg-tag-close-rgb: var(--mc-color-neutral-800-rgb);
  --mc-color-bg-tag-close-hover-rgb: var(--mc-color-neutral-900-rgb);
  --mc-color-bg-accordion-bg-rgb: var(--mc-color-neutral-900-rgb);
  --mc-color-bg-accordion-bg-hover-rgb: var(--mc-color-neutral-800-rgb);
  --mc-color-bg-accordion-text-rgb: var(--mc-color-neutral-100-rgb);

  // Text
  --mc-color-text-default-rgb: var(--mc-color-text-light-rgb);
  --mc-color-text-dark-rgb: var(--mc-color-neutral-1010-rgb);
  --mc-color-text-medium-rgb: var(--mc-color-neutral-300-rgb);
  --mc-color-text-light-rgb: var(--mc-color-neutral-90-rgb);
  --mc-color-text-tint-rgb: var(--mc-color-neutral-500-rgb);
  --mc-color-text-disabled-dark-rgb: var(--mc-color-neutral-600-rgb);
  --mc-color-text-disabled-medium-rgb: var(--mc-color-neutral-500-rgb);
  --mc-color-text-disabled-light-rgb: var(--mc-color-neutral-400-rgb);
  --mc-color-text-announce-rgb: var(--mc-color-announce-rgb);
  --mc-color-text-success-rgb: var(--mc-color-success-500-rgb);
  --mc-color-text-warning-rgb: var(--mc-color-warning-500-rgb);
  --mc-color-text-error-rgb: var(--mc-color-error-500-rgb);
  --mc-color-text-link-default-default-rgb: var(--mc-color-neutral-90-rgb);
  --mc-color-text-link-default-hover-rgb: var(--mc-color-neutral-400-rgb);
  --mc-color-text-link-default-pressed-rgb: var(--mc-color-neutral-90-rgb);
  --mc-color-text-link-default-disabled-rgb: var(--mc-color-neutral-300-rgb);
  --mc-color-text-tag-rgb: var(--mc-color-neutral-100-rgb);
  --mc-color-text-tag-close-rgb: var(--mc-color-neutral-100-rgb);

  // Icons
  --mc-color-icon-tint-light-rgb: var(--mc-color-neutral-300-rgb);
  --mc-color-icon-tint-dark-rgb: var(--mc-color-neutral-500-rgb);
  --mc-color-icon-disabled-rgb: var(--mc-color-neutral-600-rgb);
  --mc-color-icon-favorite-rgb: var(--mc-color-primary-500-rgb);
  --mc-color-icon-success-rgb: var(--mc-color-success-500-rgb);
  --mc-color-icon-warning-rgb: var(--mc-color-warning-500-rgb);
  --mc-color-icon-error-rgb: var(--mc-color-error-500-rgb);
  --mc-color-icon-twitter-rgb: var(--mc-color-twitter-rgb);
  --mc-color-icon-linkedin-rgb: var(--mc-color-linkedin-rgb);
  --mc-color-icon-facebook-rgb: var(--mc-color-facebook-rgb);
  --mc-color-icon-pinterest-rgb: var(--mc-color-pinterest-rgb);

  --mc-color-divider-subtle-rgb: var(--mc-color-neutral-800-rgb);
  --mc-color-divider-strong-rgb: var(--mc-color-neutral-500-rgb);

  --mc-color-outline-light-rgb: var(--mc-color-neutral-90-rgb);
  --mc-color-outline-dark-rgb: var(--mc-color-neutral-500-rgb);
  --mc-color-outline-disabled-rgb: var(--mc-color-neutral-800-rgb);
  --mc-color-outline-success-rgb: var(--mc-color-success-500-rgb);
  --mc-color-outline-warning-rgb: var(--mc-color-warning-500-rgb);
  --mc-color-outline-error-rgb: var(--mc-color-error-500-rgb);
  --mc-color-outline-announce-rgb: var(--mc-color-announce-rgb);

  // Progress
  --mc-color-progress-indicator-dark-rgb: var(--mc-color-neutral-1010-rgb);
  --mc-color-progress-indicator-light-rgb: var(--mc-color-neutral-90-rgb);

  // Other
  --mc-color-announce-rgb: 238, 211, 127;
  --mc-color-elevation-rgb: var(--mc-color-neutral-900-rgb);
  --mc-color-focus-rgb: 55, 135, 255;
}

// Overrides for light theme
.mc-theme-light,
.mc-invert {
  --mc-color-play-default-rgb: var(--mc-color-neutral-100-rgb);
  --mc-color-play-hover-rgb: var(--mc-color-neutral-200-rgb);
  --mc-color-play-pressed-rgb: var(--mc-color-neutral-100-rgb);

  --mc-color-tertiary-hover-rgb: var(--mc-color-neutral-200-rgb);
  --mc-color-tertiary-disabled-rgb: var(--mc-color-neutral-100-rgb);
  --mc-color-tertiary-border-default-rgb: var(--mc-color-neutral-400-rgb);

  --mc-color-third-party-default-rgb: var(--mc-color-neutral-90-rgb);
  --mc-color-third-party-hover-rgb: var(--mc-color-neutral-200-rgb);
  --mc-color-third-party-pressed-rgb: var(--mc-color-neutral-90-rgb);

  --mc-color-element-focus-outline-rgb: var(--mc-color-neutral-1010-rgb);
  --mc-color-element-focus-border-rgb: var(--mc-color-neutral-90-rgb);

  --mc-color-bg-page-default-rgb: var(--mc-color-neutral-90-rgb);
  --mc-color-bg-container-default-rgb: var(--mc-color-bg-container-light-rgb);
  --mc-color-bg-tag-rgb: var(--mc-color-neutral-200-rgb);
  --mc-color-bg-tag-close-rgb: var(--mc-color-neutral-400-rgb);
  --mc-color-bg-tag-close-hover-rgb: var(--mc-color-neutral-500-rgb);
  --mc-color-bg-accordion-bg-rgb: var(--mc-color-neutral-100-rgb);
  --mc-color-bg-accordion-bg-hover-rgb: var(--mc-color-neutral-200-rgb);
  --mc-color-bg-accordion-text-rgb: var(--mc-color-neutral-1010-rgb);

  --mc-color-text-default-rgb: var(--mc-color-text-dark-rgb);
  --mc-color-text-medium-rgb: var(--mc-color-neutral-700-rgb);
  --mc-color-text-tint-rgb: var(--mc-color-neutral-600-rgb);
  --mc-color-text-disabled-dark-rgb: var(--mc-color-neutral-500-rgb);
  --mc-color-text-disabled-medium-rgb: var(--mc-color-neutral-300-rgb);
  --mc-color-text-disabled-light-rgb: var(--mc-color-neutral-500-rgb);
  --mc-color-text-success-rgb: var(--mc-color-success-800-rgb);
  --mc-color-text-warning-rgb: var(--mc-color-warning-800-rgb);
  --mc-color-text-error-rgb: var(--mc-color-error-700-rgb);
  --mc-color-text-link-default-default-rgb: var(--mc-color-neutral-1010-rgb);
  --mc-color-text-link-default-hover-rgb: var(--mc-color-neutral-600-rgb);
  --mc-color-text-link-default-pressed-rgb: var(--mc-color-neutral-1010-rgb);
  --mc-color-text-link-default-disabled-rgb: var(--mc-color-neutral-300-rgb);
  --mc-color-text-tag-rgb: var(--mc-color-neutral-1010-rgb);
  --mc-color-text-tag-close-rgb: var(--mc-color-neutral-1010-rgb);

  --mc-color-icon-tint-dark-rgb: var(--mc-color-neutral-600-rgb);
  --mc-color-icon-disabled-rgb: var(--mc-color-neutral-400-rgb);

  --mc-color-divider-subtle-rgb: var(--mc-color-neutral-300-rgb);
  --mc-color-divider-strong-rgb: var(--mc-color-neutral-800-rgb);

  --mc-color-outline-light-rgb: var(--mc-color-neutral-400-rgb);
  --mc-color-outline-dark-rgb: var(--mc-color-neutral-1010-rgb);
  --mc-color-outline-disabled-rgb: var(--mc-color-neutral-200-rgb);

  --mc-color-elevation-rgb: var(--mc-color-neutral-500-rgb);
}
