.mc-form-group {
  svg {
    margin: -0.5em 0;
  }
}

.mc-form-prepend,
.mc-form-append {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $input-padding;
  // Reset from 16px, so sizing is based on 24px square
  font-size: 12px;
  color: var(--mc-theme-form-append);
}

.mc-form-prepend {
  padding-right: 0;
}

.mc-form-append {
  padding-left: 0;
}
