// When adding new items to this file, please also add the rgb equivalent
// to the global-rgb file as well.

// Root tokens
:root {
  --mc-color-red-100:  #FFD6D6;
  --mc-color-red-200:  #FFADAD;
  --mc-color-red-300:  #FF8585;
  --mc-color-red-400:  #FF5C5C;
  --mc-color-red-500:  #FF3333;
  --mc-color-red-600:  #FF0000;
  --mc-color-red-700:  #E00000;
  --mc-color-red-800:  #B80000;
  --mc-color-red-900:  #8F0000;

  --mc-color-pink-100: #FEB0C2;
  --mc-color-pink-200: #EA5D7E;
  --mc-color-pink-300: #E84A6F;
  --mc-color-pink-400: #E84A6F;
  --mc-color-pink-500: #E32652;
  --mc-color-pink-600: #D61A46;
  --mc-color-pink-700: #C71A42;
  --mc-color-pink-800: #B5173C;
  --mc-color-pink-900: #A21536;

  --mc-color-yellow-100: #FFDCAD;
  --mc-color-yellow-200: #FFCA85;
  --mc-color-yellow-300: #FFB856;
  --mc-color-yellow-400: #FFA733;
  --mc-color-yellow-500: #FF9000;
  --mc-color-yellow-600: #E88503;
  --mc-color-yellow-700: #D17A08;
  --mc-color-yellow-800: #B95C05;
  --mc-color-yellow-900: #663A00;

  --mc-color-green-100: #C2EBD2;
  --mc-color-green-200: #A3E0BC;
  --mc-color-green-300: #85D6A5;
  --mc-color-green-400: #66CC8F;
  --mc-color-green-500: #3EBB70;
  --mc-color-green-600: #38A865;
  --mc-color-green-700: #2E8A53;
  --mc-color-green-800: #246B40;
  --mc-color-green-900: #194D2E;

  --mc-color-purple-100: #D4CAF7;
  --mc-color-purple-200: #B7A7F1;
  --mc-color-purple-300: #9A83EC;
  --mc-color-purple-400: #7D60E6;
  --mc-color-purple-500: #4721D0;
  --mc-color-purple-600: #3C1CB0;
  --mc-color-purple-700: #301680;
  --mc-color-purple-800: #24116A;
  --mc-color-purple-900: #190B50;

  --mc-color-neutral-90:   #FFFFFF;
  --mc-color-neutral-100:  #F4F4F5;
  --mc-color-neutral-200:  #E9EAEC;
  --mc-color-neutral-300:  #D4D5D9;
  --mc-color-neutral-400:  #B3B5BC;
  --mc-color-neutral-500:  #9EA0A9;
  --mc-color-neutral-600:  #565961;
  --mc-color-neutral-700:  #43454C;
  --mc-color-neutral-800:  #303136;
  --mc-color-neutral-900:  #222326;
  --mc-color-neutral-1000: #0D0D0E;
  --mc-color-neutral-1010: #000000;
}
