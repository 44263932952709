.mc-dropdown {
  $dropdown-speed: 300ms !default;
  $mc-dropdown-body-margin: 1;
  $mc-dropdown-body-padding: 1;

  position: absolute;
  top: 0;
  left: 0;
  margin: 0;

  pointer-events: none;
  perspective: 2000px;
  z-index: $mc-zindex-modal;

  @media (min-width: $mc-bp-sm) {
    z-index: $mc-zindex-dropdown;
  }

  &__toggle {
    display: inline-block;
  }

  &__backdrop {
    opacity: 0;
    transition: opacity $dropdown-speed ease;

    .mc-dropdown--active & {
      z-index: $mc-zindex-dropdown-backdrop;
      opacity: 1;
      pointer-events: auto;

      @media (min-width: $mc-bp-sm) {
        display: none;
      }
    }
  }

  // Placeholder if we want to add an arrow style
  &__arrow {
    display: none;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;

    &-container {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100vw;
      max-height: 100vh;
      max-height: -webkit-fill-available; /* stylelint-disable-line value-no-vendor-prefix */
      z-index: $mc-zindex-dropdown;

      background: var(--mc-theme-dropdown-bg);
      border: 1px solid var(--mc-theme-dropdown-border);
      box-shadow: 0px 0px 16px 8px rgba($mc-color-gray-500, 0.1);
      opacity: 0;

      transform-origin: 50% -50px;
      transform: rotateX(-15deg);
      transition: opacity $dropdown-speed ease, transform $dropdown-speed ease;

      @media (min-width: $mc-bp-sm) {
        width: auto;
        max-width: 400px;
        max-height: 75vh;

        border: 1px solid var(--mc-theme-dropdown-border);
        border-radius: $default-radius;

        [x-placement^='bottom'] & {
          @include step(margin-top, $mc-dropdown-body-margin);
        }

        [x-placement^='top'] & {
          @include step(margin-bottom, $mc-dropdown-body-margin);
        }

        [x-placement^='left'] & {
          @include step(margin-right, $mc-dropdown-body-margin);
        }

        [x-placement^='right'] & {
          @include step(margin-left, $mc-dropdown-body-margin);
        }
      }

      @media (max-width: $mc-bp-sm) {
        height: 100%;
      }

      .mc-dropdown--active & {
        opacity: 1;
        transform: rotateX(0deg);
        pointer-events: auto;
      }
    }
  }

  &__header {
    @include step(margin-top, -$mc-dropdown-body-padding);
    border-bottom: 1px solid var(--mc-theme-dropdown-border);
  }

  &__body {
    flex: 1;
    overflow: auto;
  }

  &__close {
    border-bottom: 1px solid var(--mc-theme-dropdown-border);
    text-align: right;
  }

  &__close-icon {
    opacity: 0.5;
    transition: opacity 250ms ease;

    &:hover {
      opacity: 1;
    }
  }

  &__footer {
    @include step(margin-bottom, -$mc-dropdown-body-padding);
    border-top: 1px solid var(--mc-theme-dropdown-border);
  }

  &__item {
    display: block;
    width: 100%;
    color: var(--mc-theme-text);
    text-align: left;
    flex-shrink: 0;
    cursor: pointer;
    background: transparent;
    overflow: hidden;
    transition: background $dropdown-speed ease;

    &:hover {
      background: var(--mc-theme-dropdown-hover-bg);
    }
  }

  // For fixing scroll on mobile
  @media (max-width: $mc-bp-sm) {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transform: none !important; /* stylelint-disable-line declaration-no-important */
    overflow-x: hidden;
    overflow-y: auto;

    &__html--open {
      overflow: hidden;
      body {
        overflow: hidden;
      }
    }
  }
}
