.mc-input-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  > * {
    cursor: pointer;
  }

  + .mc-input-checkbox {
    margin-top: var(--mc-scale-2);
  }

  &__fauxbox {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: var(--mc-theme-checkbox-bg);
    border-radius: $default-radius;
    box-shadow: inset 0 0 0 1px var(--mc-theme-checkbox-border);
    transition: background 250ms ease, box-shadow 250ms ease;

    // Check
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 8px;
      bottom: 5px;
      width: 0;
      height: 2px;
      background: var(--mc-theme-checkbox-checked);
      border-radius: 3px;
      transform-origin: center left;
      transition: width 200ms ease;
    }

    &:before {
      transform: rotate(45deg);
      left: 5px;
      bottom: 9px;
      transition-delay: 180ms;
    }

    &:after {
      transform: rotate(-50deg);
      transition-delay: 0ms;
    }

    .mc-input-checkbox:active & {
      box-shadow: inset 0 0 0 2px var(--mc-theme-checkbox-active-border);
    }
  }

  input[type='checkbox']:focus + span {
    color: $mc-color-gray-600;
    box-shadow: inset 0 0 0 2px var(--mc-theme-checkbox-focused);
    outline: var(--mc-color-element-focus-outline) solid 2px;
    outline-offset: 2px;
  }

  // Actual input that's hidden
  // Keeps checkbox accessible for screen readers
  &__realbox {
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 0;
    opacity: 0;
  }

  &--checked {
    .mc-input-checkbox__fauxbox {
      &:before {
        width: 6px;
        transition-delay: 0ms;
      }

      &:after {
        width: 10px;
        transition-delay: 180ms;
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    .mc-input-checkbox__fauxbox {
      opacity: 0.5;
      background: var(--mc-theme-form-dis-bg);
      box-shadow: var(--mc-theme-form-dis-border);

      &:before,
      &:after {
        opacity: 0.3;
      }
    }

    &:active .mc-input-checkbox__fauxbox {
      box-shadow: inset 0 0 0 1px var(--mc-theme-checkbox-dis-active-border);
    }
  }
}
