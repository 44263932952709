$mc-carousel-centered-padding: 100px !default;

.mc-carousel {
  &--dots {
    padding-bottom: $grid-gutter-width;
  }

  &__slider {
    position: relative;
    pointer-events: auto;
    z-index: 0;
    transition: z-index 0ms 1s;

    // Make sure that when you hover on a carousel
    // row, the hover effects are z-indexed correctly
    // (they have to be set higher here to work)
    &:hover {
      z-index: 1;
      transition: z-index 0ms 0ms;
    }
  }

  &__container {
    width: 100%;
    pointer-events: none;

    &--no-vertical-spacing {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__inner-container {
    position: relative;
  }

  // The padding hack above that allows us to have
  // overflowing content on hover in tiles
  // without affecting the flow of the document
  // causes issues when carousels are stacked on
  // one another.
  // Ask @jones and @stephen how they figufed this out.
  &__forced-spacing {
    height: 1px;
    margin-bottom: -1px;
  }

  .slick-list {
    overflow: hidden;
    transition: height 250ms ease;
  }

  .slick-slide {
    transition: opacity 250ms ease;
  }

  .slick-track {
    margin: 0;
  }

  .slick-dots {
    position: absolute;
    left: 50%;
    bottom: -$grid-gutter-width;
    transform: translateX(-50%);

    li {
      display: inline-block;
      margin: 0 3px;
    }

    .slick-dot {
      width: 4px;
      height: 4px;
      background: var(--mc-theme-carousel-dot);
      border-radius: 4px;
      outline: 0;
      transition: background 250ms ease, width 500ms ease;
      pointer-events: none;

      &:focus {
        background: var(--mc-theme-carousel-dot-active);
      }
    }

    .slick-active {
      .slick-dot {
        background: var(--mc-theme-carousel-dot-active);
        width: 16px;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3em;
    border-radius: 3em;
    line-height: 1; // fix for vertical centering based on text line height
    background: var(--mc-theme-carousel-arrow-bg);
    color: var(--mc-theme-carousel-arrow);
    text-align: center;
    z-index: 800;
    box-shadow: var(--mc-theme-box-shadow-light);
    cursor: pointer;
    pointer-events: auto;
    transition: background 200ms ease, color 200ms ease, opacity 200ms ease;

    &:not(.slick-disabled):hover {
      background: var(--mc-theme-carousel-arrow-bg-hover);
      color: var(--mc-theme-carousel-arrow-hover);

      .mc-carousel__arrow-text {
        opacity: 1;
      }
    }

    &--prev {
      left: 0;
      transform: translate(-50%, -50%);
    }

    &--next {
      right: 0;
      transform: translate(50%, -50%);
    }

    &--no-background {
      box-shadow: none;
      background: none;

      &:not(.slick-disabled):hover {
        background: none;
        color: var(--mc-theme-carousel-arrow-hover);

        .mc-carousel__arrow-text {
          opacity: 1;
        }
      }

      &--prev {
        left: 0;
        transform: translate(-70%, -50%);
      }

      &--next {
        right: 0;
        transform: translate(70%, -50%);
      }
    }

    // Disabled state for when you can't
    // advance the slide or "go back" anymore
    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
}
