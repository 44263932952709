.mc-inset {
  &-0 {
    inset: 0;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    .position-#{$breakpoint}-absolute {
      position: absolute;
    }

    .position-#{$breakpoint}-relative {
      position: relative;
    }

    .position-#{$breakpoint}-fixed {
      position: fixed;
    }

    .position-#{$breakpoint}-sticky {
      position: sticky;
    }

    .position-#{$breakpoint}-static {
      position: static;
    }
  }
}
