@mixin fade-in-background-animation($from, $to, $time: 250ms) {
  @keyframes backgroundFadeIn {
    from {
      background-color: $from;
    }
    to {
      background-color: $to;
    }
  }

  & {
    animation: backgroundFadeIn $time;
    animation-timing-function: ease;
  }
}

@mixin fade-in-opacity {
  @keyframes mcAnimateFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  & {
    animation-name: mcAnimateFadeIn;
    animation-duration: 350ms;
    animation-iteration-count: once;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }
}

@mixin spin {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  & {
    animation: spin 500ms infinite linear;
  }
}
