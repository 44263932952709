.mc-form-select {
  &__element {
    flex: 1;
  }

  .mc-form-element__element {
    padding: 0;
  }

  // React Select overrides here
  & .mc-form-select {
    &__control {
      background: none;
      border: none;
      min-height: 0;

      color: inherit;

      &:hover {
        border: none;
      }

      &--is-focused {
        box-shadow: none;
      }
    }

    &__placeholder {
      opacity: 0.3;
    }

    &__input-container {
      font-family: inherit;
      color: inherit;
    }

    &__value-container {
      padding: $input-padding;
      cursor: text;

      > * {
        margin: 0;
        padding: 0;
        color: inherit;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      padding: $input-padding;
      color: inherit;

      cursor: pointer;

      &:hover {
        color: inherit;
      }
    }

    &__menu {
      background-color: var(--mc-theme-select-menu-bg);
      border: 1px solid var(--mc-theme-select-menu-border);
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.3);
    }

    &__option {
      cursor: pointer;
    }

    &__option--is-focused,
    &__option--is-selected,
    &__option:active {
      background-color: var(--mc-theme-select-option-bg);
      color: inherit;
    }
  }
}
