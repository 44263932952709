.mc-slider {
  position: relative;
  width: 100%;
  height: 20px;
  cursor: pointer;

  &__bar {
    position: absolute;
    left: 0;
    bottom: 50%;
    width: 100%;
    height: var(--mc-scale-1);
    background: rgba($mc-color-light, 0.2);
    border-radius: calc(var(--mc-scale-1) / 2);
    transform: translateY(50%);
    transition: width ease 300ms;
  }

  &__buffer,
  &__fill {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: rgba($mc-color-light, 0.5);
    border-radius: 2px;
    transition: width ease 300ms;
  }

  &__fill {
    background: $mc-color-primary;

    .mc-slider--color-light & {
      background: $mc-color-light;
    }
  }

  &__handle {
    position: absolute;
    left: 0;
    top: 50%;
    width: var(--mc-scale-3);
    height: var(--mc-scale-3);
    background: $mc-color-light;
    border-radius: 100%;
    transform: translateY(-50%);
    transition: left linear 300ms;
  }

  &__handle-container {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - var(--mc-scale-3));
    height: 100%;
  }
}
