/*
	Screen reader only CSS class
		@note Based on Yahoo!'s technique
		@see https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html
	* 1.
		@note `clip` is deprecated but works everywhere
		@see https://developer.mozilla.org/en-US/docs/Web/CSS/clip
	* 2.
		@note `clip-path` is the future-proof version, but not very well supported yet
		@see https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path
		@see http://caniuse.com/#search=clip-path
	* 3.
		@note preventing text to be condensed
		@see https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
	* 4.
		@note !important is important
		@note Obviously you wanna hide something
		@see https://csswizardry.com/2016/05/the-importance-of-important/
*/

.mc-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

/*
	Use in conjunction with .sr-only to only display content when it's focused.
	@note Useful for skip links
	@see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
	@note Based on a HTML5 Boilerplate technique, included in Bootstrap
*/
.mc-sr-only-focusable:focus,
.mc-sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  overflow: visible !important;
	position: static !important;
  width: auto !important;
  white-space: normal !important;
}

.mc-display-none {
  display: none;
}
