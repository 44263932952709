// New

.mc-corners {
  &--sm {
    border-radius: var(--mc-corners--sm);
  }

  &--md {
    border-radius: var(--mc-corners--md);
  }

  &--lg {
    border-radius: var(--mc-corners--lg);
  }

  &--xl {
    border-radius: var(--mc-corners--xl);
  }

  $positions: 'top-left', 'top-right', 'bottom-left', 'bottom-right';
  @each $position in $positions {
    &-#{$position}--sm {
      border-#{$position}-radius: var(--mc-corners--sm);
    }

    &-#{$position}--md {
      border-#{$position}-radius: var(--mc-corners--md);
    }

    &-#{$position}--lg {
      border-#{$position}-radius: var(--mc-corners--lg);
    }

    &-#{$position}--xl {
      border-#{$position}-radius: var(--mc-corners--xl);
    }

    &-#{$position}--none {
      border-#{$position}-radius: 0;
    }
  }

  &--circle {
    border-radius: 50%;
    isolation: isolate;
  }
}

// Old
// 2023.01.27 Should deprecate once we see usages drop / announce deprecation
.mc-corners {
  &--rounded {
    border-radius: $default-radius;
    isolation: isolate;
  }

  &--square {
    border-radius: 0;
    isolation: isolate;
  }

  @for $i from $scale-begin through $scale-end {
    &--#{$i} {
      border-radius: var(--mc-scale-#{$i});
      isolation: isolate;
    }
  }

  $positions: 'top-left', 'top-right', 'bottom-left', 'bottom-right';
  @each $position in $positions {
    &-#{$position} {
      @for $i from $scale-begin through $scale-end {
        &--#{$i} {
          border-#{$position}-radius: var(--mc-scale-#{$i});
        }
      }
    }
  }
}
