.mc-text--formatted {
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0.67em;
    margin-bottom: 0.67em;
  }

  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ul, ol {
    padding-left: 1.5em;
  }

  ul {
    li::before {
      content: "\2022";
      color: $mc-color-primary;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }
  
  ol {
    counter-reset: li;
    
    li {
      counter-increment: li;

      &::before {
        content: counter(li);
        color: $mc-color-primary;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em
      }
    }
  }
  
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
}