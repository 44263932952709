// These are modeled after the bootstrap helper classes.

// The premise is mc-m is the base for margins and mc-p is
// the base for padding.  Here are some examples:

// mc-m-6 = margin "6 scale" all around
// mc-mt-6 = margin-top "6"
// mc-mr-6 = margin-right "6"
// mc-mb-6 = margin-bottom "6"
// mc-ml-6 = margin-left "6"

@each $breakpoint in map-keys($grid-breakpoints) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .mc-m#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          margin: var(--mc-scale-#{$i}) !important;
        }
      }

      &-auto {
        margin: auto;
      }
    }

    .mc-mx#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          margin-left: var(--mc-scale-#{$i}) !important;
          margin-right: var(--mc-scale-#{$i}) !important;
        }
      }

      &-auto {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .mc-my#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          margin-top: var(--mc-scale-#{$i}) !important;
          margin-bottom: var(--mc-scale-#{$i}) !important;
        }
      }

      &-auto {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .mc-mt#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          margin-top: var(--mc-scale-#{$i}) !important;
        }
      }

      &-auto {
        margin-top: auto;
      }
    }

    .mc-mr#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          margin-right: var(--mc-scale-#{$i}) !important;
        }
      }

      &-auto {
        margin-right: auto;
      }
    }

    .mc-mb#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          margin-bottom: var(--mc-scale-#{$i}) !important;
        }
      }

      &-auto {
        margin-bottom: auto;
      }
    }

    .mc-ml#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          margin-left: var(--mc-scale-#{$i}) !important;
        }
      }

      &-auto {
        margin-left: auto;
      }
    }

    .flex#{$infix}-gap {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} { gap: var(--mc-scale-#{$i}) !important; }
      }
    }
  }
}

// Margins - scroll top only
.mc-mst {
  @for $i from $scale-begin through $scale-end {
    &-#{$i} { scroll-margin-top: var(--mc-scale-#{$i}); }
  }
}
