.logo {
  color: var(--mc-color-primary-default);
}

.container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.textContainer {
  text-align: center;
}
