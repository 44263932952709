// These are modeled after the bootstrap helper classes.

// The premise is mc-m is the base for margins and mc-p is
// the base for padding.  Here are some examples:

// mc-p-6 = padding "6 scale" all around
// mc-pt-6 = padding-top "6"
// mc-pr-6 = padding-right "6"
// mc-pb-6 = padding-bottom "6"
// mc-pl-6 = padding-left "6"
// mc-pl-3.mc-pl-md-5 = padding-left "3"
// but change to "5" at medium breakpoints and higher

@each $breakpoint in map-keys($grid-breakpoints) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .mc-p#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          padding: var(--mc-scale-#{$i}) !important;
        }
      }
    }

    .mc-px#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          padding-left: var(--mc-scale-#{$i}) !important;
          padding-right: var(--mc-scale-#{$i}) !important;
        }
      }
    }

    .mc-py#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          padding-top: var(--mc-scale-#{$i}) !important;
          padding-bottom: var(--mc-scale-#{$i}) !important;
        }
      }
    }

    .mc-pt#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          padding-top: var(--mc-scale-#{$i}) !important;
        }
      }
    }

    .mc-pr#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          padding-right: var(--mc-scale-#{$i}) !important;
        }
      }
    }

    .mc-pb#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          padding-bottom: var(--mc-scale-#{$i}) !important;
        }
      }
    }

    .mc-pl#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          padding-left: var(--mc-scale-#{$i}) !important;
        }
      }
    }
  }
}
