// These are modeled after the bootstrap helper classes.

// The premise is mc-gap is the base for gap and mc-column-gap is
// the base for column-gap.  Here are some examples:

// mc-gap-6 = flex or grid gap at "6 scale"
// mc-column-gap-6 = flex or grid column gap at "6 scale"
// mc-gap-3.mc-gap-md-5 = gap "3"
// but change to "5" at medium breakpoints and higher

@each $breakpoint in map-keys($grid-breakpoints) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .mc-gap#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          gap: var(--mc-scale-#{$i}) !important;
        }
      }
    }

    .mc-column-gap#{$infix} {
      @for $i from $scale-begin through $scale-end {
        &-#{$i} {
          column-gap: var(--mc-scale-#{$i}) !important;
        }
      }
    }
  }
}
