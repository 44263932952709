.mc-accordion {
  &__item {
    background: var(--mc-color-bg-accordion-bg);
    border-radius: $default-radius;
    cursor: pointer;
    text-align: unset;
    color: var(--mc-color-bg-accordion-text);

    &:hover {
      background: var(--mc-color-bg-accordion-bg-hover);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    padding-right: var(--mc-scale-6);
    border-radius: $default-radius;
    cursor: pointer;
    border: none;
    user-select: none; // prevents content being selected when double clicking
  }

  &__icon {
    flex-shrink: 0;
    transform: rotateX(0deg);
    transition: transform 200ms ease;
  }

  &__button[aria-expanded='true'],
  &__button[aria-selected='true'] {
    .mc-accordion__icon {
      transform: rotateX(180deg);
    }
  }
}
