.mc-input-radio {
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  &__fauxbox {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background: var(--mc-theme-radio-bg);
    border-radius: 100%;
    box-shadow: inset 0 0 0 1px var(--mc-theme-radio-border);
    cursor: pointer;
    transition: background 250ms ease, box-shadow 250ms ease;

    // Dot
    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: var(--mc-theme-radio-checked);
      transform: scale(0);
      opacity: 0;
      transition: opacity 250ms ease, transform 250ms ease;
    }
  }

  &__realbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: -1;
  }

  // States
  &:active .mc-input-radio__fauxbox,
  &__realbox:focus + .mc-input-radio__fauxbox {
    box-shadow: inset 0 0 0 2px $mc-color-gray-400;
  }

  &--checked {
    .mc-input-radio__fauxbox {
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;

    .mc-input-radio__fauxbox {
      background: var(--mc-theme-radio-dis-bg);
      box-shadow: var(--mc-theme-radio-dis-border);
      cursor: not-allowed;

      &:after {
        opacity: 0.3;
      }
    }

    &:active .mc-input-radio__fauxbox {
      box-shadow: var(--mc-theme-radio-dis-border);
    }
  }
}
