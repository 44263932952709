/* Sohne - Regular */
@font-face {
  font-family: 'Sohne';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local('Sohne Buch'),
    local('Sohne-Buch'),
    url('/fonts/sohne-buch.woff2') format('woff2'),
    url('/fonts/sohne-buch.woff') format('woff');
}

/* Sohne - Bold */
@font-face {
  font-family: 'Sohne';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src:
    local('Sohne Halbfett'),
    local('Sohne-Halbfett'),
    url('/fonts/sohne-halbfett.woff2') format('woff2'),
    url('/fonts/sohne-halbfett.woff') format('woff');
}

@font-face {
  font-family: Helvetica;
  ascent-override: 80%;
  descent-override: 20%;
  line-gap-override: normal;
  src: local(Helvetica);
}

// Legacy
.mc-text {
  &-b2 {
    @include non-scale-step(font-size, 12rem, 9.6rem, 7.6rem);
    line-height: var(--mc-lh-heading--ultra-tight);
    letter-spacing: 0.01em;
  }
  &-b1 {
    @include non-scale-step(font-size, 8.8rem, 7.4rem, 6.4rem);
    line-height: var(--mc-lh-heading--super-tight);
    letter-spacing: 0.02em;
  }

  &-d3 {
    @include step(font-size, 12);
    letter-spacing: 0.01em;
  }
  &-d2 {
    @include step(font-size, 11);
    letter-spacing: 0.03em;
  }
  &-d1 {
    @include step(font-size, 10);
    letter-spacing: 0.03em;
  }

  &-h1 {
    font-size: var(--mc-scale-9);
  }
  &-h2 {
    font-size: var(--mc-scale-8);
  }
  &-h3 {
    font-size: var(--mc-scale-7);
  }
  &-h4 {
    font-size: var(--mc-scale-6);
  }
  &-h5 {
    font-size: var(--mc-scale-5);
  }
  &-h6 {
    font-size: var(--mc-scale-4);
  }
  &-h7 {
    font-size: var(--mc-scale-3-5);
  }
  // h8 is deprecated and should be removed in the future after all usages have been replaced
  &-h8 {
    font-size: var(--mc-scale-3);
  }

  &-b2,
  &-b1 {
    font-family: $mc-font-brand !important;
    font-weight: 800;
    text-transform: uppercase;
  }

  &-d3,
  &-d2,
  &-d1 {
    font-weight: 600;
    line-height: var(--mc-lh-heading--tight);
  }

  &-h1,
  &-h2,
  &-h3,
  &-h4,
  &-h5,
  &-h6,
  &-h7,
  &-h8 {
    font-weight: 600;
    line-height: var(--mc-lh-heading);
    letter-spacing: 0.03em;
  }

  &-large {
    font-size: var(--mc-scale-5);
    line-height: var(--mc-lh-body--tight);
    letter-spacing: 0.01em;

    @media (min-width: $mc-bp-sm) {
      line-height: var(--mc-lh-body--relaxed);
    }
  }

  &-small {
    font-size: var(--mc-scale-3-5);
    line-height: var(--mc-lh-body--tight);
    letter-spacing: 0.02em;

    @media (min-width: $mc-bp-sm) {
      line-height: var(--mc-lh-body);
    }
  }

  &-x-small {
    letter-spacing: 0.02em;
    font-size: var(--mc-scale-3);
    line-height: var(--mc-lh-body--tight);
  }
}

// New
html {
  /* stylelint-disable-next-line declaration-no-important */
  font-size: $mc-step-base * 1px !important;
}

body {
  @include step(font-size, 4, !important);
  font-weight: 400;
  font-family: $mc-font-default;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  line-height: var(--mc-lh-body);
  letter-spacing: 0.02em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: rgba($mc-color-gray-500, 0.3);
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.mc-font {
  &-body {
    font-size: var(--mc-scale-4);
    line-height: var(--mc-scale-5);
  }

  &-headline1,
  &-headline2,
  &-headline3 {
    font-weight: 600;
  }

  &-headline1 {
    font-size: var(--mc-scale-10);
    line-height: calc(var(--mc-scale-10) + var(--mc-scale-1));
  }

  &-headline2 {
    font-size: var(--mc-scale-9);
    line-height: calc(var(--mc-scale-9) + var(--mc-scale-1));
  }

  &-headline3 {
    font-size: var(--mc-scale-7);
    line-height: calc(var(--mc-scale-7) + var(--mc-scale-1));
  }

  &-title {
    font-size: var(--mc-scale-5);
    line-height: calc(var(--mc-scale-5) + var(--mc-scale-1));
  }

  &-caption {
    font-size: var(--mc-scale-3);
    line-height: calc(var(--mc-scale-3) + var(--mc-scale-1));
  }
}
