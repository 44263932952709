.mc-w-100 {
  width: 100%;
}

.mc-h-100 {
  height: 100%;
}

.mc-h-fit-content {
  height: fit-content;
}

.mc-w-max-content {
  width: max-content;
}
.mc-w-fit-content {
  width: fit-content;
}

.mc-vertical-align-baseline {
  vertical-align: baseline;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .mc-h#{$infix}-auto {
      height: auto;
    }

    .mc-h#{$infix}-dvh {
      height: 100dvh;
    }
  }
}
