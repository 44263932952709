.mc-toaster {
  --toast-speed: 1000ms;
  --toast-curve: cubic-bezier(0.455, 0.03, 0.515, 0.955);

  position: fixed;
  left: 50%;
  bottom: 0;
  width: 50%;
  z-index: $mc-zindex-toast;
  transform: translateX(-50%);
  pointer-events: none;
}

.mc-toast {
  position: relative;
  pointer-events: auto;
  overflow: hidden;

  &:not(&--appear) {
    height: 0 !important; /* stylelint-disable-line declaration-no-important */
  }

  &--appear {
    overflow: visible;
  }

  &--enter {
    top: 20px;
    pointer-events: none;
    transition:
      height var(--toast-curve) var(--toast-speed),
      top var(--toast-curve) var(--toast-speed);
  }

  &--enter-active {
    top: 0;
  }

  &--exit {
    top: 0;
    transition:
      height var(--toast-curve) var(--toast-speed) var(--toast-speed),
      top var(--toast-curve) var(--toast-speed);
  }

  &--exit-active,
  &--exit-done {
    height: 0 !important; /* stylelint-disable-line declaration-no-important */
    top: -20px;
    pointer-events: none;
  }

  &__container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  &__content {
    background: var(--mc-color-neutral-800);
    border-radius: var(--mc-corners--sm);
    margin-bottom: var(--mc-scale-2);
    padding: var(--mc-scale-3);
    overflow: hidden;
    filter: drop-shadow(0 var(--mc-scale-1) var(--mc-scale-4) rgba(var(--mc-color-neutral-1010-rgb), 0.25));

    .mc-toast--enter & {
      opacity: 0;
    }

    .mc-toast--enter-active & {
      opacity: 1;
      transition: all var(--toast-curve) var(--toast-speed);
    }

    .mc-toast--exit & {
      opacity: 1;
    }

    .mc-toast--exit-active &,
    .mc-toast--exit-done & {
      opacity: 0;
      transition: all var(--toast-curve) var(--toast-speed);
    }

    .mc-toast--success & {
      background: var(--mc-color-green-800)!important;
    }

    .mc-toast--error & {
      background: var(--mc-color-error-900)!important;
    }
  }
}
