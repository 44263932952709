.mc-badge {
  box-sizing: content-box;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: $mc-color-light;
  font-weight: 600;
  border-radius: 20px;
  white-space: nowrap;

  height: var(--mc-scale-4);
  padding-top: var(--mc-scale-1);
  padding-bottom: var(--mc-scale-1);
  padding-right: var(--mc-scale-3);
  padding-left: var(--mc-scale-3);

  &--default {
    background: var(--mc-theme-badge-bg);
    color: var(--mc-theme-badge-text);
  }

  &--dim {
    background: var(--mc-theme-badge-dim-bg);
    color: var(--mc-theme-badge-dim-text);
  }

  &--transparent {
    color: var(--mc-theme-text);
    box-shadow: inset 0 0 0 1px var(--mc-theme-border-contrast);
  }

  &--primary {
    background: $mc-color-primary;
  }

  &--utility {
    background: var(--mc-color-utility-default);
  }

  &--announce {
    background: var(--mc-color-announce);
    color: var(--mc-color-neutral-1000);
  }

  &--urgency {
    background-color: var(--mc-color-warning-300);
    color: var(--mc-color-neutral-1000);
  }
}
