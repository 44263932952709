@import '../libraries/bootstrap-grid/functions';

// CSS doesn't include the width of a scrollbar in its calculations.
// This means if a user has a scrollbar (using an external mouse on a mac,
// or using a PC), then the content will be slightly misaligned).
// TODO: figure out how to detect scrollbar presence and adjust value
// to offset by the width of the scrollbar if possible?
.uncontainer {
  margin: 0 calc(50% - 50vw);
}

.container-sm {
  @include make-container();
  max-width: 824px;
}

.container-lg {
  @include make-container();
  max-width: 1600px;
}

.uncontainer-left {
  margin-left: calc(50% - 50vw);
}

.uncontainer-right {
  margin-right: calc(50% - 50vw);
}
