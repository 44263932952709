// By default, bootstrap makes the container padding half of
// the gutter width, but we want the full gutter amount on each side

.container,
.container-fluid {
  padding: 0 var(--mc-container-padding);
}

.col,
[class^='col-'],
[class*=' col-'] {
  padding: calc(var(--mc-grid-gutter-width) / 2);
}

// Row modifiers:
.no-gutters {
  margin: 0;

  > .col,
  > [class^='col-'],
  > [class*=' col-'] {
    padding: 0;
  }
}

.no-gutters-vertical {
  margin-top: 0;
  margin-bottom: 0;

  > .col,
  > [class^='col-'],
  > [class*=' col-'] {
    padding-top: 0;
    padding-bottom: 0;
  }
}


// DEPRECATED
.container--md {
  margin: 0 auto;
  max-width: 880px;
  padding: 0 ($grid-gutter-width);
}
