// If you add any new alias tokens to this file, be sure to update the
// documentation at web/ui/design-system/src/foundation/tokens/index.stories.js

// Please also add the rgb equivalent to the global-rgb file as well.
:root {
  // Primary Action
  --mc-color-primary-100-rgb: var(--mc-color-pink-100-rgb);
  --mc-color-primary-200-rgb: var(--mc-color-pink-200-rgb);
  --mc-color-primary-300-rgb: var(--mc-color-pink-300-rgb);
  --mc-color-primary-400-rgb: var(--mc-color-pink-400-rgb);
  --mc-color-primary-500-rgb: var(--mc-color-pink-500-rgb);
  --mc-color-primary-600-rgb: var(--mc-color-pink-600-rgb);
  --mc-color-primary-700-rgb: var(--mc-color-pink-700-rgb);
  --mc-color-primary-800-rgb: var(--mc-color-pink-800-rgb);
  --mc-color-primary-900-rgb: var(--mc-color-pink-900-rgb);

  // Primary Utility
  --mc-color-utility-100-rgb: var(--mc-color-purple-100-rgb);
  --mc-color-utility-200-rgb: var(--mc-color-purple-200-rgb);
  --mc-color-utility-300-rgb: var(--mc-color-purple-300-rgb);
  --mc-color-utility-400-rgb: var(--mc-color-purple-400-rgb);
  --mc-color-utility-500-rgb: var(--mc-color-purple-500-rgb);
  --mc-color-utility-600-rgb: var(--mc-color-purple-600-rgb);
  --mc-color-utility-700-rgb: var(--mc-color-purple-700-rgb);
  --mc-color-utility-800-rgb: var(--mc-color-purple-800-rgb);
  --mc-color-utility-900-rgb: var(--mc-color-purple-900-rgb);

  // Success
  --mc-color-success-100-rgb: var(--mc-color-green-100-rgb);
  --mc-color-success-200-rgb: var(--mc-color-green-200-rgb);
  --mc-color-success-300-rgb: var(--mc-color-green-300-rgb);
  --mc-color-success-400-rgb: var(--mc-color-green-400-rgb);
  --mc-color-success-500-rgb: var(--mc-color-green-500-rgb);
  --mc-color-success-600-rgb: var(--mc-color-green-600-rgb);
  --mc-color-success-700-rgb: var(--mc-color-green-700-rgb);
  --mc-color-success-800-rgb: var(--mc-color-green-800-rgb);
  --mc-color-success-900-rgb: var(--mc-color-green-900-rgb);

  // Warning
  --mc-color-warning-100-rgb: var(--mc-color-warning-100-rgb);
  --mc-color-warning-200-rgb: var(--mc-color-warning-200-rgb);
  --mc-color-warning-300-rgb: var(--mc-color-warning-300-rgb);
  --mc-color-warning-400-rgb: var(--mc-color-warning-400-rgb);
  --mc-color-warning-500-rgb: var(--mc-color-warning-500-rgb);
  --mc-color-warning-600-rgb: var(--mc-color-warning-600-rgb);
  --mc-color-warning-700-rgb: var(--mc-color-warning-700-rgb);
  --mc-color-warning-800-rgb: var(--mc-color-warning-800-rgb);
  --mc-color-warning-900-rgb: var(--mc-color-warning-900-rgb);

  // Errors
  --mc-color-error-100-rgb:  var(--mc-color-red-100-rgb);
  --mc-color-error-200-rgb:  var(--mc-color-red-200-rgb);
  --mc-color-error-300-rgb:  var(--mc-color-red-300-rgb);
  --mc-color-error-400-rgb:  var(--mc-color-red-400-rgb);
  --mc-color-error-500-rgb:  var(--mc-color-red-500-rgb);
  --mc-color-error-600-rgb:  var(--mc-color-red-600-rgb);
  --mc-color-error-700-rgb:  var(--mc-color-red-700-rgb);
  --mc-color-error-800-rgb:  var(--mc-color-red-800-rgb);
  --mc-color-error-900-rgb:  var(--mc-color-red-900-rgb);

  // Social
  --mc-color-twitter-rgb:         29, 155, 240;
  --mc-color-linkedin-rgb:        10, 102, 194;
  --mc-color-facebook-rgb:        24, 119, 242;
  --mc-color-pinterest-rgb:       231, 30, 39;
  --mc-color-google:              66, 133, 244;
  --mc-color-messenger:           0, 120, 255;
  --mc-color-paypal:              255, 196, 57;
  --mc-color-apple:               0, 0, 0;

  // Brand
  --mc-color-brand-rgb:           239, 69, 98;
}
