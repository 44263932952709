@import '@mc/design-system/src/styles/index';

.mc-page {
  &__header {
    position: sticky;
    top: 0;
    min-height: var(--mc-scale-10);
    z-index: $mc-zindex-fixed;
  }

  &__content {
    min-height: 100dvh;
  }

  &.mc-theme-sessions {
    --mc-theme-background: #fefef3;
    --mc-theme-badge-bg: #e0ffec;
    --mc-theme-badge-border-color: var(--mc-color-success-500);
    --mc-theme-badge-text: var(--mc-color-neutral-1010);
    --mc-color-background-accent: #e0ffec;

    background-color: var(--mc-theme-background);
  }
}

// Chromecast styling
body {
  --playback-logo-image: url('/_next-public/logo.svg');
}

cast-media-player {
  --background-color: #1e1e1e;
  --splash-image: url('/_next-public/logo.svg');
  --splash-size: 20%;
  --logo-image: url('/_next-public/logo.svg');
  --logo-size: 20%;
  --progress-color: $mc-color-primary;
}

.cast-env-tag {
  position: absolute;
  z-index: 9999999999;
  bottom: 0;
  right: 0;
  padding: 10px;
  background-color: $mc-color-primary;
  font-weight: bold;
}

// overides form elements error styles
.mc-form-element--error {
  box-shadow: inset 0 0 0 2px var(--mc-color-warning-500);
}

// Currently not showing error texts by design
[id*='-help-text'] {
  color: var(--mc-color-warning-500);
}

.fancy-background {
  $bg: linear-gradient(270deg, rgba(255, 255, 255, 0.1) 0.08%, rgba(153, 153, 153, 0.1) 100.08%);
  background: $bg;

  &:focus, &:hover {
    background: $bg !important;
  }
}