.mc-form-element {
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 1px var(--mc-theme-form-border);
  background: var(--mc-theme-form-bg);
  border-radius: $default-radius;
  width: 100%;
  cursor: text;
  transition: color 200ms ease, box-shadow 200ms ease;

  // The "actual" element, (input, textarea, select, etc)
  &__element {
    font-family: $mc-font-default;
    background: none;
    outline: none;
    border: 0;
    margin: 0;
    padding: $input-padding;
    color: var(--mc-theme-form-elem-color);
    width: 100%;
    font-size: var(--mc-scale-4);
    line-height: 1.5;
    letter-spacing: 0.625px;
    text-overflow: ellipsis;
    resize: none;

    &::placeholder {
      color: var(--mc-theme-form-elem-placehold-color);
    }

    // Dark and light themeing
    &[type='date' i]::-webkit-calendar-picker-indicator {
      filter: invert(100%);
    }

    .mc-invert,
    .mc-theme-light & {
      &[type='date' i]::-webkit-calendar-picker-indicator {
        filter: invert(0%);
      }
    }
  }

  &--focus {
    color: $mc-color-gray-600;
    box-shadow: inset 0 0 0 2px $mc-color-gray-400;
  }

  &--error {
    box-shadow: inset 0 0 0 2px $mc-color-error;
  }

  &--success {
    box-shadow: inset 0 0 0 2px $mc-color-success;
  }

  &--disabled {
    background: var(--mc-theme-form-dis-bg);
    box-shadow: var(--mc-theme-form-dis-border);
    cursor: not-allowed;

    // Needed to select nested children
    * {
      cursor: not-allowed;
    }

    &:hover,
    &:active {
      box-shadow: var(--mc-theme-form-dis-active);
    }

    .mc-form-element__element,
    .mc-form-element__element::selection {
      background: transparent;
      color: var(--mc-theme-form-dis-elem);
    }
  }

  // Sizes
  &--sm {
    &__element {
      font-size: var(--mc-scale-3-5);
      padding: $input-padding-small;
    }
  }

  &--lg {
    color: red;
    
    &__element {
      font-size: var(--mc-scale-5);
      padding: $input-padding-large;
    }
  }
}
