// If you add any new alias tokens to this file, be sure to update the
// documentation at web/ui/design-system/src/foundation/tokens/index.stories.js

// Please also add the rgb equivalent to the global-rgb file as well.

// Alias Tokens
:root,
.mc-theme-dark {
  // Button Actions
  --mc-color-primary-default: var(--mc-color-primary-500);
  --mc-color-primary-hover: var(--mc-color-primary-600);
  --mc-color-primary-pressed: var(--mc-color-primary-700);
  --mc-color-primary-disabled: var(--mc-color-primary-100);

  --mc-color-utility-default: var(--mc-color-utility-500);
  --mc-color-utility-hover: var(--mc-color-utility-600);
  --mc-color-utility-pressed: var(--mc-color-utility-700);
  --mc-color-utility-disabled: var(--mc-color-utility-100);

  --mc-color-play-default: var(--mc-color-neutral-90);
  --mc-color-play-hover: var(--mc-color-neutral-200);
  --mc-color-play-pressed: var(--mc-color-neutral-300);
  --mc-color-play-disabled: var(--mc-color-neutral-100);
  --mc-color-play-text-default: var(--mc-color-neutral-1010);
  --mc-color-play-text-disabled: var(--mc-color-neutral-500);

  --mc-color-secondary-default: var(--mc-color-neutral-700);
  --mc-color-secondary-hover: var(--mc-color-neutral-800);
  --mc-color-secondary-pressed: var(--mc-color-neutral-900);
  --mc-color-secondary-disabled: var(--mc-color-neutral-300);

  --mc-color-tertiary-default: transparent;
  --mc-color-tertiary-hover: var(--mc-color-neutral-800);
  --mc-color-tertiary-pressed: transparent;
  --mc-color-tertiary-disabled: var(--mc-color-neutral-600);
  --mc-color-tertiary-border-default: var(--mc-color-neutral-700);

  --mc-color-control-default: var(--mc-color-neutral-700);
  --mc-color-control-hover: var(--mc-color-neutral-800);
  --mc-color-control-pressed: var(--mc-color-neutral-900);
  --mc-color-control-disabled: var(--mc-color-neutral-300);

  --mc-color-third-party-default: var(--mc-color-neutral-1000);
  --mc-color-third-party-hover: var(--mc-color-neutral-800);
  --mc-color-third-party-pressed: var(--mc-color-neutral-1000);

  // Element focus
  --mc-color-element-focus: var(--mc-color-focus);
  --mc-color-element-focus-outline: var(--mc-color-neutral-90);
  --mc-color-element-focus-border: var(--mc-color-neutral-1010);

  // Container backgrounds
  --mc-color-bg-container-default: var(--mc-color-bg-container-dark);
  --mc-color-bg-container-dark: var(--mc-color-neutral-1000);
  --mc-color-bg-container-medium: var(--mc-color-neutral-100);
  --mc-color-bg-container-light: var(--mc-color-neutral-90);
  --mc-color-bg-container-tint-light: var(--mc-color-neutral-700);
  --mc-color-bg-container-tint-medium: var(--mc-color-neutral-800);
  --mc-color-bg-container-tint-dark: var(--mc-color-neutral-900);
  --mc-color-bg-container-disabled: var(--mc-color-neutral-100);
  --mc-color-bg-container-success: var(--mc-color-success-100);
  --mc-color-bg-container-warning: var(--mc-color-warning-100);
  --mc-color-bg-container-error: var(--mc-color-error-100);
  --mc-color-bg-page-default: var(--mc-color-neutral-1000);
  --mc-color-bg-tag: var(--mc-color-neutral-700);
  --mc-color-bg-tag-close: var(--mc-color-neutral-800);
  --mc-color-bg-tag-close-hover: var(--mc-color-neutral-900);
  --mc-color-bg-accordion-bg: var(--mc-color-neutral-900);
  --mc-color-bg-accordion-bg-hover: var(--mc-color-neutral-800);
  --mc-color-bg-accordion-text: var(--mc-color-neutral-100);

  // Text
  --mc-color-text-default: var(--mc-color-text-light);
  --mc-color-text-dark: var(--mc-color-neutral-1010);
  --mc-color-text-medium: var(--mc-color-neutral-300);
  --mc-color-text-light: var(--mc-color-neutral-90);
  --mc-color-text-tint: var(--mc-color-neutral-500);
  --mc-color-text-disabled-dark: var(--mc-color-neutral-600);
  --mc-color-text-disabled-medium: var(--mc-color-neutral-500);
  --mc-color-text-disabled-light: var(--mc-color-neutral-400);
  --mc-color-text-announce: var(--mc-color-announce);
  --mc-color-text-success: var(--mc-color-success-500);
  --mc-color-text-warning: var(--mc-color-warning-500);
  --mc-color-text-error: var(--mc-color-error-500);
  --mc-color-text-link-default-default: var(--mc-color-neutral-90);
  --mc-color-text-link-default-hover: var(--mc-color-neutral-400);
  --mc-color-text-link-default-pressed: var(--mc-color-neutral-90);
  --mc-color-text-link-default-disabled: var(--mc-color-neutral-300);
  --mc-color-text-tag: var(--mc-color-neutral-100);
  --mc-color-text-tag-close: var(--mc-color-neutral-100);

  // Icons
  --mc-color-icon-tint-light: var(--mc-color-neutral-300);
  --mc-color-icon-tint-dark: var(--mc-color-neutral-500);
  --mc-color-icon-disabled: var(--mc-color-neutral-600);
  --mc-color-icon-favorite: var(--mc-color-primary-500);
  --mc-color-icon-success: var(--mc-color-success-500);
  --mc-color-icon-warning: var(--mc-color-warning-500);
  --mc-color-icon-error: var(--mc-color-error-500);
  --mc-color-icon-twitter: var(--mc-color-twitter);
  --mc-color-icon-linkedin: var(--mc-color-linkedin);
  --mc-color-icon-facebook: var(--mc-color-facebook);
  --mc-color-icon-pinterest: var(--mc-color-pinterest);

  --mc-color-divider-subtle: var(--mc-color-neutral-800);
  --mc-color-divider-strong: var(--mc-color-neutral-500);

  --mc-color-outline-light: var(--mc-color-neutral-90);
  --mc-color-outline-dark: var(--mc-color-neutral-500);
  --mc-color-outline-disabled: var(--mc-color-neutral-800);
  --mc-color-outline-success: var(--mc-color-success-500);
  --mc-color-outline-warning: var(--mc-color-warning-500);
  --mc-color-outline-error: var(--mc-color-error-500);
  --mc-color-outline-announce: var(--mc-color-announce);

  // Progress
  --mc-color-progress-indicator-dark: var(--mc-color-neutral-1010);
  --mc-color-progress-indicator-light: var(--mc-color-neutral-90);

  // Other
  --mc-color-announce: #eed37f;
  --mc-color-elevation: var(--mc-color-neutral-900);
  --mc-color-focus: #3787ff;
}

// Overrides for light theme
.mc-theme-light,
.mc-invert {
  --mc-color-play-default: var(--mc-color-neutral-100);
  --mc-color-play-hover: var(--mc-color-neutral-200);
  --mc-color-play-pressed: var(--mc-color-neutral-100);

  --mc-color-tertiary-hover: var(--mc-color-neutral-200);
  --mc-color-tertiary-disabled: var(--mc-color-neutral-100);
  --mc-color-tertiary-border-default: var(--mc-color-neutral-400);

  --mc-color-third-party-default: var(--mc-color-neutral-90);
  --mc-color-third-party-hover: var(--mc-color-neutral-200);
  --mc-color-third-party-pressed: var(--mc-color-neutral-90);

  --mc-color-element-focus-outline: var(--mc-color-neutral-1010);
  --mc-color-element-focus-border: var(--mc-color-neutral-90);

  --mc-color-bg-container-default: var(--mc-color-bg-container-light);
  --mc-color-bg-page-default: var(--mc-color-neutral-90);
  --mc-color-bg-tag: var(--mc-color-neutral-200);
  --mc-color-bg-tag-close: var(--mc-color-neutral-400);
  --mc-color-bg-tag-close-hover: var(--mc-color-neutral-500);
  --mc-color-bg-accordion-bg: var(--mc-color-neutral-100);
  --mc-color-bg-accordion-bg-hover: var(--mc-color-neutral-200);
  --mc-color-bg-accordion-text: var(--mc-color-neutral-1010);

  --mc-color-text-default: var(--mc-color-text-dark);
  --mc-color-text-medium: var(--mc-color-neutral-700);
  --mc-color-text-tint: var(--mc-color-neutral-600);
  --mc-color-text-disabled-dark: var(--mc-color-neutral-500);
  --mc-color-text-disabled-medium: var(--mc-color-neutral-300);
  --mc-color-text-disabled-light: var(--mc-color-neutral-500);
  --mc-color-text-success: var(--mc-color-success-800);
  --mc-color-text-warning: var(--mc-color-warning-800);
  --mc-color-text-error: var(--mc-color-error-700);
  --mc-color-text-link-default-default: var(--mc-color-neutral-1010);
  --mc-color-text-link-default-hover: var(--mc-color-neutral-600);
  --mc-color-text-link-default-pressed: var(--mc-color-neutral-1010);
  --mc-color-text-link-default-disabled: var(--mc-color-neutral-300);
  --mc-color-text-tag: var(--mc-color-neutral-1010);
  --mc-color-text-tag-close: var(--mc-color-neutral-1010);

  --mc-color-icon-tint-dark: var(--mc-color-neutral-600);
  --mc-color-icon-disabled: var(--mc-color-neutral-400);

  --mc-color-divider-subtle: var(--mc-color-neutral-300);
  --mc-color-divider-strong: var(--mc-color-neutral-800);

  --mc-color-outline-light: var(--mc-color-neutral-400);
  --mc-color-outline-dark: var(--mc-color-neutral-1010);
  --mc-color-outline-disabled: var(--mc-color-neutral-200);

  --mc-color-elevation: var(--mc-color-neutral-500);
}
