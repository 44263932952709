html {
  // For overflowing tile rows
  overflow-x: hidden;
}

body {
  background: var(--mc-theme-background);
  color: var(--mc-theme-text);
}

// We want this behavior by default.
a,
a:hover {
  text-decoration: none;
}

.mc-page {
  &__header {
    position: sticky;
    top: 0;
    min-height: var(--mc-scale-10);
    z-index: $mc-zindex-fixed;
  }

  &__content {
    min-height: 100vh;
  }
}
