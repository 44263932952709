.mc-shadow {
  &--medium {
    box-shadow: 0px 0px 16px 8px rgba($mc-color-gray-500, 0.1);
  }

  &--high {
    box-shadow: 0px 0px 16px 8px rgba($mc-color-gray-500, 0.25);
  }

  &--none {
    box-shadow: none;
  }
}
