.mc-background {
  position: relative;

  &__background-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__content-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__content {
    flex: 1;
    width: 100%;
  }

  &.mc-background--position {
    &-x {
      &-left {
        .mc-background__background-container,
        .mc-background__content-container {
          justify-content: flex-start;
        }
      }

      &-center {
        .mc-background__background-container,
        .mc-background__content-container {
          justify-content: center;
        }
      }

      &-right {
        .mc-background__background-container,
        .mc-background__content-container {
          justify-content: flex-end;
        }
      }
    }

    &-y {
      &-top {
        .mc-background__background-container,
        .mc-background__content-container {
          align-items: flex-start;
        }
      }

      &-center {
        .mc-background__background-container,
        .mc-background__content-container {
          align-items: center;
        }
      }

      &-bottom {
        .mc-background__background-container,
        .mc-background__content-container {
          align-items: flex-end;
        }
      }
    }
  }

  &--loaded {
    .mc-background__background-container {
      visibility: visible;
    }
  }

  &--fit-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &--fit-container,
  &--fit-content {
    .mc-background__background-container {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &--fit-container,
  &--fit-background {
    .mc-background__content-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--size-cover {
    .mc-background__background {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &--size-contain {
    .mc-background__background {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--color-light {
    background: $mc-color-light;
  }

  &--color-medium {
    background: $mc-color-gray-100;
  }

  &--color-dark {
    background: $mc-color-dark;
  }

  &--color-transparent {
    background: transparent;
  }

  &--color-dim {
    background: rgba($mc-color-dark, 0.7);
  }
}
