// stylelint-disable declaration-no-important
.mc-text {
  // Transformation
  &--uppercase {
    letter-spacing: 0.06em;
    text-transform: uppercase !important;
  }
  &--lowercase {
    text-transform: lowercase !important;
  }
  &--capitalize {
    text-transform: capitalize !important;
  }
  &--sentence {
    &::first-letter {
      text-transform: uppercase !important;
    }
  }
  &--sup {
    display: inline-block;
    transform: translate(15%, 5%);
    vertical-align: super;
    font-size: 50%;
    letter-spacing: 0.2em;
  }

  // Weight / opacity
  &--bold {
    font-weight: 600 !important;
  }
  &--strike {
    text-decoration: line-through !important;
  }
  @at-root strong,
    b {
    font-weight: 600 !important;
  }
  @at-root em {
    font-style: italic !important;
  }
  &--italic {
    font-style: italic !important;
  }
  @at-root i {
    font-style: italic !important;
  }
  &--normal {
    font-weight: 400 !important;
  }
  &--light {
    font-weight: 300 !important;
  }
  &--underline {
    text-decoration: underline !important;
  }
  @at-root u {
    text-decoration: underline !important;
  }

  // Alternate font
  &--brand {
    font-family: $mc-font-brand !important;
    line-height: 0.85;
    letter-spacing: 0.01em;
    font-weight: 500;
  }

  // Treatments
  &--airy {
    font-weight: 400 !important;
    letter-spacing: 0.24em !important;
    text-transform: uppercase !important;
  }

  // x-lines-max: take up as much line height as needed, but cap at x height
  // x-lines: take up x lines of height, even if the text doesn't fill space
  @for $i from 1 through $scale-end {
    &--#{$i}-lines,
    &--#{$i}-lines-max {
      // https://css-tricks.com/almanac/properties/l/line-clamp/
      display: block;
      /* stylelint-disable */
      display: -webkit-box;
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      /* stylelint-enable */
      overflow: hidden;
      -webkit-line-clamp: $i;
    }
  }

  // For single lines, reset back to just a block element
  // so the standard ellipsis overflow will work (ignore webkit box)
  &--1-line,
  &--1-line-max {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @for $i from 2 through $scale-end {
    &--#{$i}-lines {
      // Default body / anything not defined
      height: calc(#{$i} * var(--mc-lh-body) * 1em);

      // Headings
      &[class*='mc-text-h'],
      [class*='mc-text-h'] & {
        height: calc(#{$i} * var(--mc-lh-heading) * 1em);
      }

      // Small text
      &.mc-text-small,
      .mc-text-small & {
        height: calc(#{$i} * var(--mc-lh-body) * 1em);
      }

      // Large text
      &.mc-text-large,
      .mc-text-large & {
        height: calc(#{$i} * var(--mc-lh-body--relaxed) * 1em);
      }
    }

    &--#{$i}-lines-max {
      height: auto;
    }
  }

  // Colors

  // todo:
  // deprecate --error and --success
  // we now have a new syntax:
  // --color-success and --color-error
  &--error {
    color: $mc-color-error;
  }
  &--success {
    color: $mc-color-success;
  }
  &--highlight {
    color: $mc-color-highlight;
  }
  &-color-success {
    color: $mc-color-success;
  }

  $colors: (
    light: $mc-color-light,
    primary: $mc-color-primary,
    gray-100: $mc-color-gray-100,
    gray-200: $mc-color-gray-200,
    gray-300: $mc-color-gray-300,
    gray-400: $mc-color-gray-400,
    gray-500: $mc-color-gray-500,
    gray-600: $mc-color-gray-600,
    medium: var(--mc-color-text-medium),
    success: $mc-color-success,
    progress: $progress-text-color,
    tint: var(--mc-color-text-tint),
    dark: var(--mc-color-text-dark),
    yellow-100: var(--mc-color-yellow-100),
    secondary: var(--mc-color-secondary-default),
    disabled-light: var(--mc-color-text-disabled-light),
    purple-500: var(--mc-color-utility-500),
    // NEUTRAL
    neutral-90: var(--mc-color-neutral-90),
    neutral-100: var(--mc-color-neutral-100),
    neutral-200: var(--mc-color-neutral-200),
    neutral-300: var(--mc-color-neutral-300),
    neutral-400: var(--mc-color-neutral-400),
    neutral-500: var(--mc-color-neutral-500),
    neutral-600: var(--mc-color-neutral-600),
    neutral-700: var(--mc-color-neutral-700),
    neutral-800: var(--mc-color-neutral-800),
    neutral-900: var(--mc-color-neutral-900),
    neutral-1000: var(--mc-color-neutral-1000),
    neutral-1010: var(--mc-color-neutral-1010),
  );

  @each $name, $var in $colors {
    &-color--#{$name} {
      color: #{$var};
    }
  }

  @each $selector in hover, focus {
    @each $name, $var in $colors {
      &-#{$selector}-color--#{$name} {
        &:#{$selector} {
          color: #{$var};
        }
      }
    }
  }

  // Links
  &--link,
  &--link:hover,
  &--bare-link:hover,
  .mc-text--bare-link-parent:hover &--bare-link,
  .mc-text--bare-link-parent:focus &--bare-link {
    text-decoration: underline;
    cursor: pointer;
  }

  // Add cursor pointer to parent wrapper
  @at-root .mc-text--bare-link-parent:hover {
    cursor: pointer;
  }

  // Misc
  &--monospace {
    font-family: monospace !important;
  }
  &--nowrap {
    white-space: nowrap;
  }
  &--pre-wrap {
    white-space: pre-wrap;
  }
  &--balance {
    text-wrap: balance;
  }

  // Cursor
  &--pointer {
    cursor: pointer;
  }
}

// Responsive
@each $breakpoint in map-keys($grid-breakpoints) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .mc-text#{$infix}--left {
      text-align: left !important;
    }
    .mc-text#{$infix}--right {
      text-align: right !important;
    }
    .mc-text#{$infix}--center {
      text-align: center !important;
    }
    .mc-text#{$infix}--justify {
      text-align: justify !important;
    }
  }
}
