.mc-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  z-index: $mc-zindex-modal-backdrop;

  &--dark {
    background: rgba($mc-color-dark, 0.8);
  }

  &--extra-dark {
    background: rgba($mc-color-dark, 0.95);
  }
}

.mc-modal {
  @include fade-in-opacity();

  & {
    animation-fill-mode: none;

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: $mc-zindex-modal-backdrop;
    overflow-x: hidden;
    overflow-y: auto;
  }

  // Shell div to position the modal with margin
  &__viewport {
    position: relative;
    width: auto;
    margin: 1.75rem 0;
    z-index: $mc-zindex-modal-backdrop;

    // vertically centered stuffs
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);
    height: calc(100% - 3.5rem);
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    outline: 0;

    &:focus {
      outline: none;
    }

    .mc-modal--large & {
      max-width: 888px;
    }

    .mc-modal--medium & {
      max-width: 530px;
    }

    .mc-modal--small & {
      max-width: 336px;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;

    margin: var(--mc-scale-2);
    opacity: 0.5;
    z-index: $mc-zindex-modal + 2;

    font-size: 100%;

    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }

    svg {
      vertical-align: top;
    }
  }

  &__html--open {
    overflow: hidden;
    body {
      overflow: hidden;
    }
  }
}
