:root {
  --mc-bp-xs: #{$mc-bp-xs};
  --mc-bp-sm: #{$mc-bp-sm};
  --mc-bp-md: #{$mc-bp-md};
  --mc-bp-lg: #{$mc-bp-lg};
  --mc-bp-xl: #{$mc-bp-xl};

  --mc-grid-columns: #{$grid-columns};
  --mc-grid-gutter-width: calc(var(--mc-scale-8) / 2);

  --mc-container-padding: calc(var(--mc-grid-gutter-width) * 2);
  --mc-container-max-width: #{$container-max-width - ($grid-gutter-width * 4)};
  --mc-maximum-content-width: #{$xl-container-max-width};

  // container overflow calculations e.g. for Carousel overflow/peek
  --mc-page-content-width: calc(min(var(--mc-maximum-content-width), 100vw));
  --mc-page-margin: calc(
    100vw - var(--mc-page-content-width) +
      (var(--mc-container-padding) + var(--mc-grid-gutter-width))
  );
  // usage:
  //   margin: 0 var(--mc-overflow-outer-margin);
  //   padding: 0 var(--mc-overflow-inner-padding);
  --mc-overflow-inner-padding: calc(var(--mc-page-margin) / 2);
  --mc-overflow-outer-margin: calc(
    (-1 * var(--mc-overflow-inner-padding)) - (var(--mc-grid-gutter-width) / 2)
  );
}
