.mc-separator {
  height: 1px;
  border: none;
  // Right now default matches subtle so component can be passed
  // any 'kind'.
  background: var(--mc-color-divider-subtle);

  // Default
  &--subtle {
    background: var(--mc-color-divider-subtle);
  }

  &--strong {
    background: var(--mc-color-divider-strong);
  }
}
