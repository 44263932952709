.rounded-box {
  background-color: $mc-color-light;
  border-radius: $default-radius;
  padding: $grid-gutter-width;
  color: $mc-color-dark;

  @media (max-width: $mc-bp-sm) {
    padding: 2rem;
  }

  &__header {
    font-weight: 400;
    line-height: 3.4rem;
    font-size: 2.4rem;
    letter-spacing: 0.05rem;
    margin: 0 0 2rem 0;
  }

  &__subheader {
    font-weight: 400;
    line-height: 2.4rem;
    font-size: 1.6rem;
    color: rgba($mc-color-dark, 0.5);
    margin: 0;
  }
}
