.mc-tooltip {
  position: absolute;
  max-width: 400px; // fallback when calc unsupported
  max-width: calc(min(400px, 100%));

  opacity: 0;

  pointer-events: none;

  &--show {
    opacity: 1;
    pointer-events: auto;
    z-index: $mc-zindex-tooltip;
    // Not full coverage supported yet, but degrades gracefully.
    // Forces correct sizing for tooltip content when triggered by a button
    // with a narrower width than would fit the content.
    width: max-content;
  }

  &__toggle {
    display: inline-block;
  }

  &__content {
    padding: var(--mc-scale-3);
    background: var(--mc-theme-tooltip-bg);
    border: 1px solid var(--mc-theme-tooltip-border);
    border-radius: 3px;
    box-shadow: 0 4px 24px rgba($mc-color-dark, 0.3);
  }

  &__arrow,
  &__arrow:after {
    position: absolute;
    width: 0;
    height: 0;

    border-style: solid;
    border-color: var(--mc-theme-tooltip-arrow);
  }

  &__arrow:after {
    content: "";
    border-color: var(--mc-theme-tooltip-arrow-after);
  }

  &[data-popper-placement^="top"] {
    .mc-tooltip__arrow {
      &,
      &:after {
        left: calc(50% - 5px);
        bottom: -5px;

        border-width: 5px 5px 0 5px;
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }

      &:after {
        bottom: 1px;
      }
    }
  }

  &[data-popper-placement^="bottom"] {
    .mc-tooltip__arrow {
      &,
      &:after {
        left: calc(50% - 5px);
        top: -5px;

        border-width: 0 5px 5px 5px;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
      }

      &:after {
        top: 1px;
      }
    }
  }

  &[data-popper-placement^="right"] {
    .mc-tooltip__arrow {
      &,
      &:after {
        left: -5px;
        top: calc(50% - 5px);

        border-width: 5px 5px 5px 0;
        border-left-color: transparent;
        border-top-color: transparent;
        border-bottom-color: transparent;
      }

      &:after {
        left: 1px;
      }
    }
  }

  &[data-popper-placement^="left"] {
    .mc-tooltip__arrow {
      &,
      &:after {
        right: -5px;
        top: calc(50% - 5px);

        border-width: 5px 0 5px 5px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }

      &:after {
        right: 1px;
      }
    }
  }
}
