.spinning-loader__spinner {
  width: 8rem;
  height: 8rem;
  border: 0.3rem solid $mc-color-gray-500;
  border-top-color: white;
  border-radius: 50%;
  animation: rotation .8s ease infinite;
}

.spinning-loader {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}


// ANIMATION
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
