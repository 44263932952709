.flex-none {
  flex: none;
}

.mc-no-scrollbars {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow: auto; /* Ensures scrolling is possible */
}

.mc-no-scrollbars::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
