:root {
  --mc-border--default: 1px solid var(--mc-theme-form-border);
  --mc-border--md: 2px solid var(--mc-theme-form-border);
}

.mc-border {

  &--default {
    border: var(--mc-border--default);
  }

  &--md {
    border: var(--mc-border--md);
  }

  &-t {
    &--default {
      border-top: var(--mc-border--default);
    }

    &--md {
      border-top: var(--mc-border--md);
    }
  }

  &-l {
    &--default {
      border-left: var(--mc-border--default);
    }

    &--md {
      border-left: var(--mc-border--md);
    }
  }

  &-b {
    &--default {
      border-bottom: var(--mc-border--default);
    }

    &--md {
      border-bottom: var(--mc-border--md);
    }
  }

  &-r {
    &--default {
      border-right: var(--mc-border--default);
    }

    &--md {
      border-right: var(--mc-border--md);
    }
  }
}
