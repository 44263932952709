.mc-tile {
  position: relative;
  backface-visibility: hidden;

  transform: translateZ(0);

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &--crop {
    overflow: hidden;
  }

  &--auto {
    width: 100%;
    height: 100%;
  }

  &--1x1,
  &--square {
    @include aspect-ratio(1, 1);
  }

  &--2x3 {
    @include aspect-ratio(2, 3);
  }

  &--3x4,
  &--poster {
    @include aspect-ratio(3, 4);
  }

  &--4x3,
  &--video {
    @include aspect-ratio(4, 3);
  }

  &--9x16,
  &--portrait {
    @include aspect-ratio(9, 16);
  }

  &--16x9,
  &--widescreen-video {
    @include aspect-ratio(16, 9);
  }

  &--21x9,
  &--cinematic {
    @include aspect-ratio(21, 9);
  }

  &__component {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &--pointer {
    cursor: pointer;
  }
}

.mc-tile-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mc-tile-video {
  overflow: hidden;
  opacity: 0;
  transition: 0.2s ease opacity;

  &.mc-tile-video--show {
    opacity: 1;
  }

  &__mute {
    position: absolute;
    right: 0;
    top: 0;
    margin: 12px;
    z-index: 1;
  }

  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .bc-player {
    width: 100%;
    height: 100%;
  }
}

.mc-tile-overlay {
  $mc-tile-overlay-color: $mc-color-gray-100;

  pointer-events: none;

  &--gradient-top {
    background: linear-gradient(
        to bottom,
        rgba($mc-tile-overlay-color, 1) 0,
        rgba($mc-tile-overlay-color, 0.5) 50%,
        rgba($mc-tile-overlay-color, 0) 90%
    ) center no-repeat;
  }

  &--gradient-bottom {
    background: linear-gradient(
        to top,
        rgba($mc-tile-overlay-color, 1) 0,
        rgba($mc-tile-overlay-color, 0.5) 50%,
        rgba($mc-tile-overlay-color, 0) 90%
    ) center no-repeat;
  }

  &--gradient-right {
    background: linear-gradient(
        to left,
        rgba($mc-tile-overlay-color, 1) 0,
        rgba($mc-tile-overlay-color, 0.6) 25%,
        rgba($mc-tile-overlay-color, 0) 45%
    ) center no-repeat;
  }

  &--gradient-left {
    background: linear-gradient(
        to right,
        rgba($mc-tile-overlay-color, 1) 0,
        rgba($mc-tile-overlay-color, 0.6) 25%,
        rgba($mc-tile-overlay-color, 0) 45%
    ) center no-repeat;
  }

  &--spotlight {
    background: radial-gradient(
        farthest-side at center top,
        rgba($mc-color-background, 0) 33%,
        rgba($mc-color-background, 1) 100%
    ),
    linear-gradient(
        to right,
        rgba($mc-color-background, 1) 0%,
        rgba($mc-color-background, 0) 10%
    ),
    linear-gradient(
        to left,
        rgba($mc-color-background, 1) 0%,
        rgba($mc-color-background, 0) 10%
    );
  }

  &--solid {
    background: rgba($mc-tile-overlay-color, 0.8);
  }
}

.mc-tile-caption {
  display: flex;
  pointer-events: none;

  &__content {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    transition: transform 0.2s ease,
    padding 0.2s ease;
    // Give only direct children auto, so you get more accurate click areas
    > * {
      pointer-events: auto;
    }
  }

  // Modifiers
  &--x-left {
    .mc-tile-caption__content {
      text-align: left;
    }
  }

  &--x-center {
    .mc-tile-caption__content {
      text-align: center;
    }
  }

  &--x-right {
    .mc-tile-caption__content {
      text-align: right;
    }
  }

  &--y-top {
    .mc-tile-caption__content {
      align-self: flex-start;
    }
  }

  &--y-center {
    .mc-tile-caption__content {
      align-self: center;
    }
  }

  &--y-bottom {
    .mc-tile-caption__content {
      align-self: flex-end;
    }
  }

  &--y-below {
    .mc-tile-caption__content {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.mc-tile-check {
  cursor: pointer;

  &__check {
    position: absolute;
    right: 0;
    top: 0;
    width: 52px;
    height: 52px;
    margin: 16px;
    color: $mc-color-light;
  }
}

.mc-tile-progress {
  pointer-events: none;

  &__content {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 2px;
    background: $mc-color-gray-200;
  }

  &__bar {
    height: 100%;
    background: $mc-color-primary;
  }
}
