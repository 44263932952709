.mc-form-tags {
  // outer box shadow and overflow hidden prevents scrollbars from cutting off corners
  box-shadow: 0 0 0 1px var(--mc-theme-form-border);
  overflow: hidden;

  &__inner-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow: auto;
    max-height: 296px; // 8 lines
  }

  .mc-form-element__element {
    width: auto;
    overflow-x: hidden;

    &::placeholder {
      font-size: var(--mc-scale-3-5);
    }
  }

  &__tag-wrapper {
    cursor: default;
    margin-top: var(--mc-scale-3);
    margin-left: var(--mc-scale-3);
  }

  &__tag {
    display: inline-flex;
    align-items: center;
    border-radius: var(--mc-scale-5);
    white-space: nowrap;
    background: var(--mc-color-bg-tag);
    color: var(--mc-color-text-tag);
    padding: var(--mc-scale-1) var(--mc-scale-3);
  }

  &__tag-close {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--mc-scale-2);
    // Negatively margin to position the x a little nicer
    margin-right: calc(-1 * var(--mc-scale-1) - 3px);
    background: var(--mc-color-bg-tag-close);
    color: var(--mc-color-text-tag-close);
    border-radius: 100%;
    width: var(--mc-scale-4);
    height: var(--mc-scale-4);
    transition: background 250ms ease;

    .mc-icon {
      opacity: 0.5;
    }

    &:hover {
      background: var(--mc-color-bg-tag-close-hover);
      .mc-icon {
        opacity: 1;
      }
    }
  }
}
