svg {
  vertical-align: middle;
}

// Force old icons to behave like new icons
// should be able to deprecate some time in 2099
.c-button {
  .mc-icon--legacy {
    width: var(--mc-scale-5);
    height: var(--mc-scale-5);
  }
}

.mc-icon {
  width: 2em;
  height: 2em;

  &--md {
    width: var(--mc-scale-5);
    height: var(--mc-scale-5);
  }

  &--sm {
    width: var(--mc-scale-4);
    height: var(--mc-scale-4);
  }

  &--lg {
    width: var(--mc-scale-6);
    height: var(--mc-scale-6);
  }

  @for $i from 1 through 16 {
    &--#{$i} {
      @include step(font-size, $i);
    }

    &--scale-#{$i} {
      width: var(--mc-scale-#{$i});
      height: var(--mc-scale-#{$i});
    }
  }

  &--rotate-90 {
    transform: rotate(90deg);
  }

  &--rotate-180 {
    transform: rotate(180deg);
  }

  &--rotate-270 {
    transform: rotate(270deg);
  }

  &--circled {
    padding: 0.25em;
    border: 1px solid currentColor;
    border-radius: 50%;
  }

  // Color modification
  &--transparent {
    fill: none;
  }
}
