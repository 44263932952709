:root {
  // These share line-height values between
  // the font definitions and the max-height
  // helpers (eg: 'mc-text--2-line')
  --mc-lh-heading--ultra-tight: 0.9;
  --mc-lh-heading--super-tight: 1;
  --mc-lh-heading--tight: 1.15;
  --mc-lh-heading: 1.25;
  --mc-lh-body: 1.5;
  --mc-lh-body--tight: 1.45;
  --mc-lh-body--relaxed: 1.6;
}
