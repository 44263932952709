/* Sohne - Schmal - Semibold */
@font-face {
  font-family: 'Sohne Schmal';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Sohne Schmal Dreiviertelfett'),
    local('Sohne-Schmal-Dreiviertelfett'),
    url('/_next-public/fonts/sohne-schmal-dreiviertelfett.woff2')
      format('woff2'),
    url('/_next-public/fonts/sohne-schmal-dreiviertelfett.woff') format('woff');
}

/* adjust the system "Impact" font to minimize CLS shift once Sohne Schmal is loaded */
@font-face {
  font-family: "Impact-fallback";
  font-display: swap;
  src: local("Impact");
  size-adjust: 73%;
}
