.mc-elevation {
  &--low {
    box-shadow: 0 4px 4px var(--mc-color-elevation);
  }

  &--medium {
    box-shadow: 0 6px 8px var(--mc-color-elevation);
  }

  &--high {
    box-shadow: 0 8px 12px var(--mc-color-elevation);
  }
}
