.mc-animation {
  z-index: 0; // sets all to same level
  &--important {
    &.mc-animation--animating {
      z-index: 1;
    }
  }

  &--zoom {
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.29, 1.185, 1, 1);

    &.mc-animation--animating {
      transform: scale(1.05);
    }
  }

  &--ken-burns {
    overflow: hidden;

    > * {
      transform: scale(1);
      transition: transform 8s ease;
    }

    &.mc-animation--animating > * {
      transform: scale(1.1);
      transition: transform 15s cubic-bezier(0.02, 0.075, 0.42, 1);
    }
  }

  &--lift {
    transition: transform 0.75s cubic-bezier(0.29, 1.185, 1, 1);

    &.mc-animation--animating {
      transform: translateY(-0.75em);
    }
  }

  &--zoom-tile {
    $zoom-duration: 400ms;
    $bg-duration: 400ms;
    $extract-duration: 500ms;
    $bezier: cubic-bezier(0.5, -0.11, 0.355, 1.01);

    .mc-tile__content {
      transition:
        left $zoom-duration $bezier 0ms,
        top $zoom-duration $bezier 0ms,
        right $zoom-duration $bezier 0ms,
        bottom $zoom-duration $bezier 0ms;
    }

    .mc-tile-caption__content {
      background: transparent;
      transition:
        background $bg-duration $bezier 0ms,
        padding $zoom-duration $bezier 0ms;
    }

    .mc-tile__reveal-on-hover {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transform: translateY(7px);
      transition:
        opacity $extract-duration $bezier 0ms,
        transform $extract-duration $bezier 0ms,
        max-height 0ms ease $extract-duration;
    }


    &.mc-animation--animating {
      .mc-tile__content {
        top: -32px;
        left: -32px;
        right: -32px;
        transition:
          left $zoom-duration $bezier 0ms,
          top $zoom-duration $bezier 0ms,
          right $zoom-duration $bezier 0ms,
          bottom $zoom-duration $bezier 0ms;
      }

      .mc-tile__darken-bg-on-hover {
        .mc-tile-caption__content {
          background: $mc-color-gray-100;
          padding: 12px 32px 24px 32px;
          transition:
            background $bg-duration ease 0ms,
            padding $zoom-duration $bezier 0ms;
        }
      }

      .mc-tile__reveal-on-hover {
        max-height: 9999999px;
        opacity: 1;
        transform: translateY(0);
        transition:
          opacity $extract-duration ease $zoom-duration,
          transform $extract-duration ease $zoom-duration,
          max-height 0ms ease 0ms;
      }
    }
  }

  // Show / Hide
  $fade-duration: 200ms;
  $delay-duration: 1000ms;

  &--show {
    opacity: 0;
    transition: opacity $fade-duration ease;

    &.mc-animation--animating {
      opacity: 1;
      transition: opacity $fade-duration ease;
    }

    &-delayed {
      opacity: 0;
      transition: opacity $delay-duration ease;

      &.mc-animation--animating {
        opacity: 1;
        transition: opacity $delay-duration ease;
      }
    }
  }

  &--hide {
    opacity: 1;
    transition: opacity $fade-duration ease;

    &.mc-animation--animating {
      opacity: 0;
      transition: opacity $fade-duration ease;
    }

    &-delayed {
      opacity: 1;
      transition: opacity $delay-duration ease;

      &.mc-animation--animating {
        opacity: 0;
        transition: opacity $delay-duration ease;
      }
    }
  }
}
