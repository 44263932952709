@import 'semantic-hex';
@import 'semantic-rgb';

:root {
  --mc-color-ai-500: rgb(101, 34, 242);

  --mc-gradient-ai: radial-gradient(
      70% 70% at 50% 0%,
      rgba(251, 125, 247, 0.8) 0%,
      rgba(222, 156, 253, 0) 100%
    ),
    radial-gradient(
      70% 100% at 30% 120%,
      rgb(230, 12, 73) 0%,
      var(--mc-color-ai-500) 100%
    );
}
