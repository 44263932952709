.mc-sticky {
  position: fixed;
  left: 0;
  right: 0;
  z-index: $mc-zindex-sticky;
  width: 100%;
  background: rgba($mc-color-gray-100, 0.95);
  transition: transform 750ms ease, opacity 750ms ease;

  &--top {
    opacity: 0;
    transform: translateY(-100%);
  }

  &--bottom {
    opacity: 0;
    transform: translateY(100%);
  }

  &__show {
    opacity: 1;
    transform: translateY(0);
  }
}
