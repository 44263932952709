// If you add any new alias tokens to this file, be sure to update the
// documentation at web/ui/design-system/src/foundation/tokens/index.stories.js

// Please also add the rgb equivalent to the global-rgb file as well.
:root {
  // Primary Action
  --mc-color-primary-100: var(--mc-color-pink-100);
  --mc-color-primary-200: var(--mc-color-pink-200);
  --mc-color-primary-300: var(--mc-color-pink-300);
  --mc-color-primary-400: var(--mc-color-pink-400);
  --mc-color-primary-500: var(--mc-color-pink-500);
  --mc-color-primary-600: var(--mc-color-pink-600);
  --mc-color-primary-700: var(--mc-color-pink-700);
  --mc-color-primary-800: var(--mc-color-pink-800);
  --mc-color-primary-900: var(--mc-color-pink-900);

  // Primary Utility
  --mc-color-utility-100: var(--mc-color-purple-100);
  --mc-color-utility-200: var(--mc-color-purple-200);
  --mc-color-utility-300: var(--mc-color-purple-300);
  --mc-color-utility-400: var(--mc-color-purple-400);
  --mc-color-utility-500: var(--mc-color-purple-500);
  --mc-color-utility-600: var(--mc-color-purple-600);
  --mc-color-utility-700: var(--mc-color-purple-700);
  --mc-color-utility-800: var(--mc-color-purple-800);
  --mc-color-utility-900: var(--mc-color-purple-900);

  // Success
  --mc-color-success-100: var(--mc-color-green-100);
  --mc-color-success-200: var(--mc-color-green-200);
  --mc-color-success-300: var(--mc-color-green-300);
  --mc-color-success-400: var(--mc-color-green-400);
  --mc-color-success-500: var(--mc-color-green-500);
  --mc-color-success-600: var(--mc-color-green-600);
  --mc-color-success-700: var(--mc-color-green-700);
  --mc-color-success-800: var(--mc-color-green-800);
  --mc-color-success-900: var(--mc-color-green-900);

  // Warning
  --mc-color-warning-100: var(--mc-color-yellow-100);
  --mc-color-warning-200: var(--mc-color-yellow-200);
  --mc-color-warning-300: var(--mc-color-yellow-300);
  --mc-color-warning-400: var(--mc-color-yellow-400);
  --mc-color-warning-500: var(--mc-color-yellow-500);
  --mc-color-warning-600: var(--mc-color-yellow-600);
  --mc-color-warning-700: var(--mc-color-yellow-700);
  --mc-color-warning-800: var(--mc-color-yellow-800);
  --mc-color-warning-900: var(--mc-color-yellow-900);

  // Errors
  --mc-color-error-100:  var(--mc-color-red-100);
  --mc-color-error-200:  var(--mc-color-red-200);
  --mc-color-error-300:  var(--mc-color-red-300);
  --mc-color-error-400:  var(--mc-color-red-400);
  --mc-color-error-500:  var(--mc-color-red-500);
  --mc-color-error-600:  var(--mc-color-red-600);
  --mc-color-error-700:  var(--mc-color-red-700);
  --mc-color-error-800:  var(--mc-color-red-800);
  --mc-color-error-900:  var(--mc-color-red-900);

  // Social
  --mc-color-twitter:       #1D9BF0;
  --mc-color-linkedin:      #0A66C2;
  --mc-color-facebook:      #1877F2;
  --mc-color-pinterest:     #E71E27;
  --mc-color-google:        #4285f4;
  --mc-color-messenger:     #0078ff;
  --mc-color-paypal:        #ffc439;
  --mc-color-apple:         #000000;

  // Brand
  --mc-color-brand:         #EF4562;
}
