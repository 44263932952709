@mixin step($attribute, $value, $i: false) {
  $important: if($i, '!important', '');

  #{$attribute}: var(--mc-scale-#{$value}) #{$important};
}

@mixin non-scale-step(
  $attribute,
  $valueDesktop,
  $valueTablet,
  $valueMobile,
  $i: false
) {
  $important: if($i, '!important', '');

  #{$attribute}: #{$valueMobile} #{$important};

  @media (min-width: $mc-bp-md) {
    #{$attribute}: #{$valueTablet} #{$important};
  }

  @media (min-width: $mc-bp-lg) {
    #{$attribute}: #{$valueDesktop} #{$important};
  }
}
