// Todo
// Deprecate all of these...they should live in variables now!
// Move them over as needed, then eventually deprecate.

// Themes
:root,
.mc-theme-dark {
  // NOTE: we have to set this here, since the <body> tag is out of scope
  color: var(--mc-theme-text);
  // Main colors
  --mc-theme-background:              #{$mc-color-dark};
  --mc-theme-text:                    #{$mc-color-light};
  --mc-theme-text-hr-span-after:      #{rgba($mc-color-light, 0.3)};
  --mc-theme-border-contrast:         #{rgba($mc-color-light, 0.5)};
  --mc-theme-border-contrast-hover:   #{$mc-color-light};
  --mc-theme-applepay-border:         none;

  // Badges
  --mc-theme-badge-bg:                #{$mc-color-light};
  --mc-theme-badge-text:              #{$mc-color-dark};
  --mc-theme-badge-dim-bg:            #{$mc-color-gray-200};
  --mc-theme-badge-dim-text:          #{$mc-color-light};

  // Carousel
  --mc-theme-carousel-dot:            #{$mc-color-gray-400};
  --mc-theme-carousel-dot-active:     #{$mc-color-light};
  --mc-theme-carousel-arrow-bg:       #{$mc-color-gray-200};
  --mc-theme-carousel-arrow-bg-hover: #{$mc-color-gray-300};
  --mc-theme-carousel-arrow:          #{$mc-color-gray-500};
  --mc-theme-carousel-arrow-hover:    #{$mc-color-light};

  // Dropdown
  --mc-theme-dropdown-backdrop:   #{rgba($mc-color-dark, 0.9)};
  --mc-theme-dropdown-border:     #{$mc-color-gray-200};
  --mc-theme-dropdown-bg:         #{$mc-color-gray-100};
  --mc-theme-dropdown-shadow:     #{$mc-color-dark};
  --mc-theme-dropdown-hover-bg:   #{$mc-color-gray-200};

  // Tooltip
  --mc-theme-tooltip-bg: #{$mc-color-gray-200};
  --mc-theme-tooltip-border: #{$mc-color-gray-400};
  --mc-theme-tooltip-arrow: #{$mc-color-gray-400};
  --mc-theme-tooltip-arrow-after: #{$mc-color-gray-200};

  // Checkbox
  --mc-theme-checkbox-bg:                 #{$mc-color-gray-100};
  --mc-theme-checkbox-border:             #{$mc-color-gray-500};
  --mc-theme-checkbox-checked:            #{$mc-color-light};
  --mc-theme-checkbox-active-border:      #{$mc-color-gray-400};
  --mc-theme-checkbox-focused:            #{$mc-color-gray-500};
  --mc-theme-checkbox-dis-bg:             #{$mc-color-gray-700};
  --mc-theme-checkbox-dis-border:         #{$mc-color-gray-600};
  --mc-theme-checkbox-dis-active-border:  #{$mc-color-gray-200};

  // Form elements
  --mc-theme-form-bg:                     #{$mc-color-gray-100};
  --mc-theme-form-border:                 #{$mc-color-gray-200};
  --mc-theme-form-elem-color:             #{$mc-color-light};
  --mc-theme-form-elem-placehold-color:   #{rgba($mc-color-light, 0.5)};
  --mc-theme-form-dis-bg:                 #{$mc-color-gray-200};
  --mc-theme-form-dis-border:             none;
  --mc-theme-form-dis-active:             none;
  --mc-theme-form-dis-elem:               #{rgba($mc-color-light, 0.5)};
  --mc-theme-form-append:                 #{rgba($mc-color-light, 0.3)};

  // Radio colors
  --mc-theme-radio-bg:          #{$mc-color-gray-100};
  --mc-theme-radio-border:      #{$mc-color-gray-200};
  --mc-theme-radio-checked:     #{$mc-color-light};
  --mc-theme-radio-dis-bg:      var(--mc-theme-radio-bg);
  --mc-theme-radio-dis-border:  none;

  // Select colors
  --mc-theme-select-menu-bg: #{$mc-color-gray-100};
  --mc-theme-select-menu-border: #{$mc-color-gray-200};
  --mc-theme-select-option-bg: #{$mc-color-gray-200};

  // box shadows
  --mc-theme-box-shadow-light:   none;

  // images
  --mc-theme-image-wrapper-bg: #{$mc-color-gray-100};

  // Checkout flow
  --mc-theme-checkout-unselected-text-color: #{$mc-color-gray-500};
  --mc-theme-checkout-selected-text-color: #{$mc-color-text};
  --mc-theme-checkout-selected-bg-color: #{rgba($mc-color-gray-100, 0.8)};
  --mc-theme-checkout-separator-color: #{$mc-color-gray-100};
}

.mc-theme-light,
.mc-invert {
  // NOTE: we have to set this here, since the <body> tag is out of scope
  color: var(--mc-theme-text);
  // Main colors
  --mc-theme-background:              #{$mc-color-light};
  --mc-theme-text:                    #{$mc-color-dark};
  --mc-theme-text-hr-span-after:      #{rgba($mc-color-dark, 0.3)};
  --mc-theme-border-contrast:         #{rgba($mc-color-dark, 0.5)};
  --mc-theme-border-contrast-hover:   #{$mc-color-dark};
  --mc-theme-applepay-border:         inset 0 0 0 1px #{$mc-color-dark};

  // Badges
  --mc-theme-badge-bg:                #{$mc-color-gray-200};
  --mc-theme-badge-text:              #{$mc-color-light};
  --mc-theme-badge-dim-bg:            #{$mc-color-gray-600};
  --mc-theme-badge-dim-text:          #{$mc-color-dark};

  // Carousel
  --mc-theme-carousel-dot:            #{rgba($mc-color-dark, 0.6)};
  --mc-theme-carousel-dot-active:     #{$mc-color-dark};
  --mc-theme-carousel-arrow-bg:       #{$mc-color-light};
  --mc-theme-carousel-arrow-bg-hover: #{$mc-color-gray-700};
  --mc-theme-carousel-arrow: #{$mc-color-gray-300};
  --mc-theme-carousel-arrow-hover: #{$mc-color-gray-300};

  // Dropdown
  --mc-theme-dropdown-border:     #{$mc-color-gray-600};
  --mc-theme-dropdown-bg:         #{$mc-color-light};
  --mc-theme-dropdown-hover-bg:   #{$mc-color-gray-700};

  // Tooltip
  --mc-theme-tooltip-bg:            #{$mc-color-light};
  --mc-theme-tooltip-border:        #{$mc-color-gray-700};
  --mc-theme-tooltip-arrow:         #{$mc-color-gray-700};
  --mc-theme-tooltip-arrow-after:   #{$mc-color-light};

  // Checkbox
  --mc-theme-checkbox-bg:                 #{$mc-color-light};
  --mc-theme-checkbox-border:             #{$mc-color-gray-500};
  --mc-theme-checkbox-checked:            #{$mc-color-gray-200};
  --mc-theme-checkbox-active-border:      #{$mc-color-gray-400};
  --mc-theme-checkbox-focused:            #{$mc-color-gray-400};
  --mc-theme-checkbox-dis-bg:             #{$mc-color-gray-700};
  --mc-theme-checkbox-dis-border:         #{$mc-color-gray-600};
  --mc-theme-checkbox-dis-active-border:  #{$mc-color-gray-600};

  // Form elementa
  --mc-theme-form-bg:                     #{$mc-color-light};
  --mc-theme-form-border:                 #{$mc-color-gray-500};
  --mc-theme-form-elem-color:             #{$mc-color-dark};
  --mc-theme-form-elem-placehold-color:   #{rgba($mc-color-dark, 0.6)};
  --mc-theme-form-dis-bg:                 #{$mc-color-gray-700};
  --mc-theme-form-dis-border:             inset 0 0 0 1px #{$mc-color-gray-600};
  --mc-theme-form-dis-active:             #{$mc-color-gray-600};
  --mc-theme-form-dis-elem:               #{rgba($mc-color-dark, 0.3)};
  --mc-theme-form-append:                 #{rgba($mc-color-dark, 0.3)};

  // Radio colors
  --mc-theme-radio-bg:          #{$mc-color-light};
  --mc-theme-radio-border:      #{$mc-color-gray-500};
  --mc-theme-radio-checked:     #{$mc-color-dark};
  --mc-theme-radio-dis-bg:      #{$mc-color-gray-700};
  --mc-theme-radio-dis-border:  inset 0 0 0 1px #{$mc-color-gray-600};

  // Select colors
  --mc-theme-select-menu-bg:      #{$mc-color-light};
  --mc-theme-select-menu-border:  #{$mc-color-gray-700};
  --mc-theme-select-option-bg:    #{$mc-color-gray-700};

  // box shadows
  --mc-theme-box-shadow-light:   0px 0px 16px 8px rgba(158, 160, 169, 0.25);

  // images
  --mc-theme-image-wrapper-bg:   #{$mc-color-light};

  // Checkout flow
  --mc-theme-checkout-unselected-text-color: var(--mc-color-neutral-700);
  --mc-theme-checkout-selected-text-color: #{$mc-color-dark};
  --mc-theme-checkout-selected-bg-color: #F4F4F5;
  --mc-theme-checkout-separator-color: var(--mc-color-neutral-200);
}
