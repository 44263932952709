@mixin mc-button {
  padding: var(--mc-scale-3) var(--mc-scale-6);
  font-size: var(--mc-scale-4);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $mc-color-light;
  font-weight: 600;
  line-height: var(--mc-scale-5);
  letter-spacing: 0.01em;
  text-transform: capitalize;
  border-radius: var(--mc-scale-2);
  transition: background 250ms ease, box-shadow 250ms ease;
  vertical-align: middle;
  white-space: nowrap;

  // needed to correct the
  // input type='submit' styles
  border: 0;
  font-family: $mc-font-default;

  .mc-sr-only {
    text-transform: none;
  }

  &.disabled,
  &:disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &[aria-disabled='true'] {
    // Override reakit pointer-events: none to allow hover over a disabled but still focusable button
    pointer-events: initial !important;
  }

  &:not(:disabled):not(.disabled):not([aria-disabled='true']) {
    cursor: pointer;
  }

  &:focus-visible {
    outline: var(--mc-color-element-focus-outline) solid 2px;
    outline-offset: 2px;
  }

  // Size Variations
  &--sm {
    font-size: var(--mc-scale-3);
    line-height: var(--mc-scale-4);
    padding: var(--mc-scale-2) var(--mc-scale-5);
  }

  &--lg {
    padding: var(--mc-scale-4) var(--mc-scale-7);
    font-size: var(--mc-scale-5);
    line-height: var(--mc-scale-6);
  }

  // Deprecate eventually
  // (not used in new comps as of 5/16/22)
  &--xs {
    padding: var(--mc-scale-1) var(--mc-scale-4);
  }

  // Display Variations

  &--full-width {
    width: 100%;
  }

  &--with-icon {
    display: flex;
    align-self: center;
    justify-content: center;

    span {
      margin-left: 1.3rem;
    }
  }

  &--loading {
    position: relative;
    pointer-events: none;

    .c-button__content {
      opacity: 0;
      display: inherit;
    }

    .c-button__loader {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -1em;

      @include spin;
    }
  }

  // Kind Variations

  &--primary {
    background: var(--mc-color-primary-default);

    &:not(:disabled):not(.disabled) {
      &:hover {
        background: var(--mc-color-primary-hover);
        // Needed to override old previously defined
        // hover color in masterclass repo
        color: var(--mc-color-text-light);
      }

      &:active {
        background: var(--mc-color-primary-pressed);
      }
    }
  }

  &--secondary {
    background: var(--mc-color-secondary-default);

    &:not(:disabled):not(.disabled) {
      &:hover {
        background: var(--mc-color-secondary-hover);
      }

      &:active {
        background: var(--mc-color-secondary-pressed);
      }
    }
  }

  &--tertiary {
    background: var(--mc-color-tertiary-default);
    box-shadow: inset 0 0 0 2px var(--mc-color-tertiary-border-default);
    color: var(--mc-theme-text);

    &:not(:disabled):not(.disabled) {
      &:hover {
        background: var(--mc-color-tertiary-hover);
      }

      &:active {
        background: var(--mc-color-tertiary-pressed);
      }
    }
  }

  &--utility {
    background: var(--mc-color-utility-default);

    &:not(:disabled):not(.disabled) {
      &:hover {
        background: var(--mc-color-utility-hover);
      }

      &:active {
        background: var(--mc-color-utility-pressed);
      }
    }
  }

  &--play {
    background: var(--mc-color-play-default);
    color: var(--mc-color-play-text-default);

    &:not(:disabled):not(.disabled) {
      &:hover {
        background: var(--mc-color-play-hover);
      }

      &:active {
        background: var(--mc-color-play-pressed);
      }
    }
  }

  // Deprecate (no new uses after 5/16/22)
  &--link {
    background: none;
    color: var(--mc-theme-text);

    span {
      position: relative;

      &:after {
        position: absolute;
        bottom: -4px;
        left: 0;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        pointer-events: none;
        background: var(--mc-theme-background);
        opacity: 0;
        transition: opacity 250ms cubic-bezier(0.25, 0.1, 0.25, 1);
      }
    }

    &:not(:disabled):not(.disabled) {
      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 1;
      }
    }
  }

  &--linked-in {
    background-color: $mc-color-linkedin;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: lighten($mc-color-linkedin, 6.5%);
      }

      &:active {
        background-color: $mc-color-linkedin;
      }
    }
  }

  &--symmetrical {
    padding: var(--mc-scale-3);

    /* stylelint-disable-next-line */
    &.c-button--xs {
      padding: var(--mc-scale-1);
    }
    /* stylelint-disable-next-line */
    &.c-button--sm {
      padding: var(--mc-scale-2);
    }
    /* stylelint-disable-next-line */
    &.c-button--lg {
      padding: var(--mc-scale-4);
    }
  }

  &--control {
    background: var(--mc-color-control-default);
    width: var(--mc-scale-9);
    height: var(--mc-scale-9);
    border-radius: var(--mc-scale-9);
    padding: 0;
    overflow: hidden;

    &.c-button--sm {
      width: var(--mc-scale-8);
      height: var(--mc-scale-8);
    }

    &:not(:disabled):not(.disabled) {
      &:hover {
        background: var(--mc-color-control-hover);
      }

      &:active {
        background: var(--mc-color-control-pressed);
      }
    }
  }

  &--google {
    background-color: $mc-color-light;
    color: $mc-color-dark;
    border: 1px solid $mc-color-gray-600;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: darken($mc-color-light, 6.5%);
      }

      &:active {
        background-color: $mc-color-light;
      }
    }
  }

  &--facebook {
    background-color: $mc-color-facebook;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: lighten($mc-color-facebook, 6.5%);
      }

      &:active {
        background-color: $mc-color-facebook;
      }
    }
  }

  &--twitter {
    background-color: $mc-color-twitter;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: lighten($mc-color-twitter, 6.5%);
      }

      &:active {
        background-color: $mc-color-twitter;
      }
    }
  }

  &--messenger {
    background-color: $mc-color-messenger;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: lighten($mc-color-messenger, 6.5%);
      }

      &:active {
        background-color: $mc-color-messenger;
      }
    }
  }

  &--pinterest {
    background-color: $mc-color-pinterest;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: lighten($mc-color-pinterest, 6.5%);
      }

      &:active {
        background-color: $mc-color-pinterest;
      }
    }
  }

  &--paypal {
    background-color: $mc-color-paypal;
    color: $mc-color-dark;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: lighten($mc-color-paypal, 6.5%);
        color: $mc-color-dark;
      }

      &:active {
        background-color: $mc-color-paypal;
        color: $mc-color-dark;
      }
    }
  }

  &--success {
    background-color: $mc-color-success;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: lighten($mc-color-success, 6.5%);
      }

      &:active {
        background-color: $mc-color-success;
      }
    }
  }

  &--apple {
    background-color: $mc-color-apple;

    &:not(:disabled):not(.disabled) {
      &:hover {
        background-color: $mc-color-apple;
      }

      &:active {
        background-color: $mc-color-apple;
      }
    }
  }
}

.c-button {
  @include mc-button;
}
