.mc-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--mc-theme-image-wrapper-bg);
  overflow: hidden;
  height: 100%;
}

.mc-img-wrapper__image {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
